<template>
    <div class="container  py-4">
      <!-- OFFERTS FORM -->
      <div class="row">
        <div class="col-md-12" v-if="hasLocations === true && loading === false">
         

          <div class="card">
            <div class="card-header">
              <h5>{{ $t('card.title.offertLocation') }}</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <!-- LOCATION -->
                <div class="col-md-6" style="margin-bottom: 0 !important">
                  <div class="form-floating mb-3" >
                    <LocationsDropdown 
                        :apiData="apiData"
                        :userData="userData"
                        v-model="offert.location"
                        :class="{'is-invalid': errors.region }"
                        :requestRegion="requestRegion"
                        :searchable="false"
                        id="locationField"
                        />
                      
                  </div>
                </div>
                <!-- LOCATION END -->
                <!-- START DATE  -->
                <div class="col-md-3" style="margin-bottom: 0 !important">                  
                  
                  <div class="form-floating mb-3" >
                    <flat-pickr
                      ref="startPicker"
                      :config="startConfig"
                      class="form-control"
                      placeholder="Start"
                      id="startNameField"
                      name="start"
                      @onChange="setStart"  
                    />
                    <label for="startNameField">{{ $t('form.label.start') }}</label>
                    <div class="invalid-feedback" v-if="errors.start">
                      <p v-for="(item, x) in errors.start" :key='x'>{{ errors.start[x] }}</p>
                    </div>
                  </div>
                
                </div>
                <!-- START DATE END -->
                 <!-- END DATE  -->
                <div class="col-md-3" style="margin-bottom: 0 !important">                  
                  
                  <div class="form-floating mb-3">
                    <flat-pickr
                      ref="endPicker"
                      :config="endConfig"
                      class="form-control"
                      placeholder="Ende"
                      id="endNameField"
                      name="end"
                      :disabled="!offert.start"
                      @onChange="setEnd"
                    />
                    <label for="endNameField">{{ $t('form.label.end') }}</label>
                    <div class="invalid-feedback" v-if="errors.end">
                      <p v-for="(item, x) in errors.end" :key='x'>{{ errors.end[x] }}</p>
                    </div>
                  </div>
                
                </div>
                <!-- END DATE END -->
                <!-- REPEAD -->
                <div class="col-md-3" style="margin-bottom: 0 !important">
                  <div class="form-floating mb-3">
                    <select class="form-select" aria-label="Default select example" id="repeadField" v-model="offert.repead" :disabled="!offert.end"  @change="setRepeadToConfig()" >
                      <option value="no" selected>Nie</option>
                      <option value="daily" :disabled="range === 'day' || range === 'week' || range === 'month' " >Täglich</option>
                      <option value="weekly"  :disabled="range === 'week' || range === 'month' "  >Wöchentlich</option>
                      <option value="monthly" :disabled="range === 'month' " >Monatlich</option>
                    </select>
                    <label for="repeadField" class="form-label" >{{ $t('form.label.repead') }}</label>
                    <div class="invalid-feedback" v-if="errors.repead">
                      <p v-for="(item, x) in errors.repead" :key='x' >{{ errors.repead[x] }}</p>
                    </div>
                  </div>
                </div>
                <!-- REPEAD END -->
                <!-- REPEAD TO -->
                <div class="col-md-3" style="margin-bottom: 0 !important">                  
                  
                  <div class="form-floating mb-3">
                    <flat-pickr
                      
                      :config="repeadToConfig"
                      :class="{'form-control': true, 'is-invalid': errors.repeadTo}"
                      placeholder="Bis"
                      id="repeadToField"
                      name="repeadTo"
                      :disabled="offert.repead === 'no'"
                      v-model="offert.repeadTo"
                    />
                    <label for="repeadToField">{{ $t('form.label.to') }}</label>
                    <div class="text-danger" v-if="errors.repeadTo">
                      <p v-for="(item, x) in errors.repeadTo" :key='x'>{{ errors.repeadTo[x] }}</p>
                    </div>
                  </div>
                
                </div>
                <!-- REPEAD TO END -->
                <!-- PRICE MODEL -->
                <div class="col-md-2" style="margin-bottom: 0 !important">
                 
                  <div class="form-floating mb-3">
                   
                    <select 
                    class="form-select" 
                    aria-label="Default select example" 
                    id="priceModelField" 
                    v-model="offert.priceModel" 
                    disabled
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :title="$t('tooltip.auction')"
                    v-if="!offert.end || auctionPossible === false"
                    >
                      <option value="fix" selected>Fixpreis</option>
                      <option value="auction" >Auktion</option>
                    </select>

                    <select 
                    class="form-select" 
                    aria-label="Default select example" 
                    id="priceModelField" 
                    v-model="offert.priceModel" 
                    v-else

                    >
                      <option value="fix" selected>Fixpreis</option>
                      <option value="auction" >Auktion</option>
                    </select>


                    <label for="priceModelField" class="form-label" >{{ $t('form.label.priceModel') }}</label>
                    <div class="invalid-feedback" v-if="errors.priceModel">
                      <p v-for="(item, x) in errors.priceModel" :key='x' >{{ errors.priceModel[x] }}</p>
                    </div>
                  </div>
                </div>
                <!-- PRICE MODEL END -->
                <!-- PRICE -->
                <div class="col-md-2" style="margin-bottom: 0 !important">
                  <div class="input-group  mb-3">
                    <span class="input-group-text" id="basic-addon1">
                      {{ $t('form.label.chf') }}
                    </span>
                      <div class="form-floating">
                      
                        <input type="text"  :class="{'form-control': true, 'is-invalid': errors.price}"  id="PriceField" v-model="offert.price" placeholder="Preis" :disabled="!offert.end" >
                        <label for="PriceField" class="form-label" v-if="offert.priceModel === 'fix'">{{ $t('form.label.price') }}</label>
                        <label for="PriceField" class="form-label" v-if="offert.priceModel === 'auction'">{{ $t('form.label.startPrice') }}</label>
                        
                      </div>
                    
                  </div>
                  <div class="text-danger" v-if="errors.price" style="margin-top:-15px;">
                    <p v-for="(item, x) in errors.price" :key='x'>{{ errors.price[x] }}</p>
                  </div>
                </div>
                <!-- PRICE END -->
                <!-- STEPS -->
                <div class="col-md-2" style="margin-bottom: 0 !important">
                  <div class="input-group  mb-3">
                    <span class="input-group-text" id="basic-addon1">
                      {{ $t('form.label.chf') }}
                    </span>
                      <div class="form-floating">
                      
                        <input type="text"  :class="{'form-control': true}"  id="StepsField" v-model="offert.steps" placeholder="Schritte" :disabled="offert.priceModel === 'fix'">
                        <label for="StepsField" class="form-label" >{{ $t('form.label.steps') }}</label>
                      </div>
                    
                  </div>
                </div>
                <!-- STEPS END -->
              </div>
            </div>
            <div class="card-footer align-self-end justify-content-end d-flex" style="width:100%">
            <button class="btn btn-primary" :disabled="!offert.location  || !offert.start || !offert.end || !offert.price" v-if="offert.priceModel === 'fix' && addOffertLoading === false" @click="addOffert()">{{ $t('button.placeOffert') }}</button>
            <button class="btn btn-primary" :disabled="true" v-if="offert.priceModel === 'fix' && addOffertLoading === true" @click="addOffert()">
              <span class="spinner-border spinner-border-sm" aria-hidden="true" style="margin-right:5px"></span>
              <span role="status">{{ $t('button.placeOffert') }}</span>

            </button>
            <button class="btn btn-primary" :disabled="!offert.location  || !offert.start || !offert.end || !offert.price" v-if="offert.priceModel === 'auction' && addOffertLoading === false"  @click="addOffert()">{{ $t('button.placeAuction') }}</button>
            <button class="btn btn-primary" :disabled="true" v-if="offert.priceModel === 'auction' && addOffertLoading === true" @click="addOffert()">
              <span class="spinner-border spinner-border-sm" aria-hidden="true" style="margin-right:5px"></span>
              <span role="status">{{ $t('button.placeAuction') }}</span>

            </button>
            </div>
          </div>
        </div>
      </div>
      <!-- LOCATIONS TEASER -->
      <div class="row" v-if="hasLocations === false && loading === false">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body text-center">
              <h5>{{ $t('teaser.title.location') }}</h5>
              <h6>{{ $t('teaser.content.location') }}</h6>
              <button class="btn btn-primary" @click="$router.push('/profil/add-location')">{{ $t('button.addLocation') }}</button>
            </div>
          </div>
        </div>
      </div>
      <!-- LOCATIONS TEASER END -->
      <!-- OFFERTS FORM END -->
      <div class="row" v-if="hasLocations === true && loading === false">
        <!-- TAB NAV -->
        <div class="col-md-12">
          <ul class="nav nav-tabs d-flex">
            <li class="nav-item">
              <a :class="{'nav-link': true, 'active': selectedStatus ===''}" aria-current="page" href="#"  @click.prevent="$router.push('/offerts');selectedStatus ='';currentPage=1">{{ $t('tab.all') }}</a>
            </li>
            <li class="nav-item">
              <a :class="{'nav-link': true, 'active': selectedStatus ==='offen'}" href="#" @click.prevent="$router.push('/offerts/open');selectedStatus ='offen';currentPage=1">{{ $t('tab.open') }}</a>
            </li>
            <li class="nav-item">
              <a :class="{'nav-link': true, 'active': selectedStatus ==='gebucht'}" href="#"  @click.prevent="$router.push('/offerts/booked');selectedStatus ='gebucht';currentPage=1">{{ $t('tab.booked') }}  <span class="badge text-bg-danger" v-if="bookedOfferts > 0">{{bookedOfferts}}</span></a>
            </li>
            <li class="nav-item">
              <a :class="{'nav-link': true, 'active': selectedStatus ==='bestätigt'}" href="#"  @click.prevent="$router.push('/offerts/confirmed');selectedStatus ='bestätigt';currentPage=1">{{ $t('tab.confirmed') }}</a>
            </li>
            <li class="d-flex align-items-center ms-auto" >
              <label class="form-check-label me-2 text-muted" for="flexSwitchCheckDefault">{{ $t('form.label.hideExpired') }}</label>
              <div class="form-check form-switch" >
                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="mainFilter.hideExpired" style="margin-top:6px; "> 
              </div>
            </li>
          </ul>
        </div>
        <!-- TAB NAV END -->
        <!-- MAIN FILTER -->
        <div class="col-md-12" >
          <div class="card">
              <div class="card-header">
                <h5>{{ $t('card.title.filter') }}</h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-2" style="margin-bottom:0 !important">
                    <div class="form-floating mb-3" >
                      <flat-pickr              
                        :config="filterPickerStart"
                        class="form-control"
                        placeholder="Von"
                        id="filterVon"
                        name="von"
                        v-model="mainFilter.von"
                      />
                      <label for="filterVon">{{ $t('form.label.from') }}</label>
                    </div>
                  </div>
                  <div class="col-md-2" style="margin-bottom:0 !important">
                    <div class="form-floating mb-3" >
                      <flat-pickr              
                        :config="filterPickerEnd"
                        class="form-control"
                        placeholder="Bis"
                        id="filterBis"
                        name="bis"
                        v-model="mainFilter.bis"
                      />
                      <label for="filterBis">{{ $t('form.label.to') }}</label>
                    </div>
                  </div>
                  <div class="col-md-2 " style="margin-bottom:0 !important">
                    <div class="form-floating mb-3">
                    
                      <RegionDropdown 
                        :apiData="apiData"
                        v-model="mainFilter.region"
                        :class="{'is-invalid': errors.region }"
                        :requestRegion="requestRegion"
                        :searchable="true"
                        />
                      <div class="invalid-feedback  d-block" v-if="errors.region">
                          <p v-for="(item, x) in errors.region" :key='x' >{{ errors.region[x] }}</p>
                      </div> 
                        
                    </div>
                  </div>
                  <div class="col-md-2" style="margin-bottom:0 !important">
                    <div class="form-floating mb-3">
                    
                      <MultiDropdown 
                        v-model="mainFilter.type"
                        :class="{'is-invalid': errors.region }"
                        :searchable="false"
                        :data="dropdownTypeData"
                        :name="$t('form.label.type')"
                        />
                      <div class="invalid-feedback  d-block" v-if="errors.region">
                          <p v-for="(item, x) in errors.region" :key='x' >{{ errors.region[x] }}</p>
                      </div> 
                        
                    </div>
                  </div>
                  <div class="col-md-2" style="margin-bottom:0 !important">
                    <div class="form-floating mb-3">
                    
                      <MultiDropdown 
                      
                        v-model="mainFilter.priceModel"
                        :class="{'is-invalid': errors.region }"
                        :searchable="false"
                        :data="dropdownPricemodelData"
                        :name="$t('form.label.priceModel')"
                        />
                      <div class="invalid-feedback  d-block" v-if="errors.region">
                          <p v-for="(item, x) in errors.region" :key='x' >{{ errors.region[x] }}</p>
                      </div> 
                        
                    </div>
                  </div>
                  <!-- PRICE -->
                  <div class="col-md-2" style="margin-bottom: 0 !important">
                    <div class="input-group  mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        {{ $t('form.label.chf') }}
                      </span>
                        <div class="form-floating">            
                          <input type="text"  :class="{'form-control': true}"  id="PriceFilter" v-model="mainFilter.maxPrice" placeholder="Max. Preis" >
                          <label for="PriceFilter" class="form-label">{{ $t('form.label.maxPrice') }}</label>             
                        </div>           
                    </div>
                  </div>
                  <!-- PRICE END -->
                  <div class="col-md-12 filterBadges" style="margin-bottom:0 !important">
                    <template v-if="mainFilter.von">
                      <span class="badge bg-secondary" style="margin-right:5px">{{ $t('form.label.from') }}: {{mainFilter.von}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="removeFilterBadge('von')" /></span>&nbsp;
                    </template>
                    <template v-if="mainFilter.bis">
                      <span class="badge bg-secondary" style="margin-right:5px">{{ $t('form.label.to') }}: {{mainFilter.bis}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="removeFilterBadge('bis')" /></span>&nbsp;
                    </template>
                    <template v-for="(item, x) in mainFilter.region" :key='x' >
                      <span class="badge bg-secondary" style="margin-right:5px">{{item.name}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="removeFilterBadge('region', x)" /></span>&nbsp;
                    </template>
                    <template v-for="(item, x) in mainFilter.type" :key='x' >
                      <span class="badge bg-secondary" style="margin-right:5px">{{item.name}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="removeFilterBadge('type', x)" /></span>&nbsp;
                    </template>
                    <template v-for="(item, x) in mainFilter.priceModel" :key='x' >
                      <span class="badge bg-secondary" style="margin-right:5px">{{item.name}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="removeFilterBadge('priceModel', x)" /></span>&nbsp;
                    </template>
                    <template  v-if="mainFilter.maxPrice">
                      <span class="badge bg-secondary" style="margin-right:5px">Max. CHF {{mainFilter.maxPrice}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="removeFilterBadge('maxPrice')" /></span>&nbsp;
                    </template>
                  </div>
                </div>
              </div>
          </div>
        </div>




          


          
        <!-- MAIN FILTER END -->
        <!-- PAGINATION NAV -->
        <nav aria-label="Page navigation" v-if="paginatedOfferts.length > 0">
          <ul class="pagination">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">{{ $t('button.previous') }}</a>
            </li>
            <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
              <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
              <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">{{ $t('button.next') }}</a>
            </li>
            
          </ul>
        </nav>
        <!-- PAGINATION NAV END -->
        <div v-for="(item, y) in paginatedOfferts" :key='y' class="col-md-12">
          <!-- CARD OFFERT -->
          <div class="card mb-3 d-flex">
            <div class="row g-0">
              <!-- MAP OFFERT -->
              <div class="col-md-4" style="margin-bottom:0 !important">
                <MapboxMap 
                  access-token="pk.eyJ1Ijoic2JlbmN6ZSIsImEiOiJjbTFiYXVrY3YwbWY0MmpzYm0wamc0bDhiIn0.R_6w0DsjbSGASOg2Snz8Ng"
                  style="min-height: 320px"
                  :center="[item.location.long,item.location.lat]"
                  :map-style="mapboxTheme"
                  :zoom="16"
                  :scrollZoom="false"
                >
                <MapboxNavigationControl position="top-left" :showCompass="false" />
                <MapboxMarker :lng-lat="[item.location.long,item.location.lat]" color="#f9b233" />
                </MapboxMap>
              </div>
              <!-- MAP OFFERT END -->
              <div class="col-md-8 d-flex flex-column" style="margin-bottom: 0 !important; padding-left:0">
                <div class="card-body w-100">
                  <!-- OFFERT CONTENT -->
                  <div class="row">
                    <div class="col-8">
                      <h5 class="card-title">{{ item.location.name }} <span  style="margin-right:5px"  :class="{'badge':true, 'text-bg-secondary': item.status ==='offen', 'text-bg-warning': item.status ==='geboten' || item.status ==='gebucht',  'text-bg-success':item.status ==='bestätigt',  'text-bg-danger':item.status ==='keine gebote'}">{{ item.status }}</span>
                      <span class="badge text-bg-danger" v-if="checkExpired(item.start)">abgelaufen</span></h5>
                    </div>
                    <div class="col-4 d-flex justify-content-end" v-if="item.price_model === 'auction' && !item.bid ">
                      <h5 >{{ $t('form.label.startPrice') }} {{ $t('form.label.chf') }} {{ item.price }}</h5>
                    </div>
                    <div class="col-4 d-flex justify-content-end" v-if="item.bid">
                      <h5 >{{ $t('form.label.bid') }} {{ $t('form.label.chf') }} {{ item.bid }}</h5>
                    </div>
                    <div class="col-4 d-flex justify-content-end" v-if="item.price_model === 'fix'">
                      <h5 >{{ $t('form.label.chf') }} {{ item.price }}</h5>
                    </div>
                    <div class="col-md-6 cardContentElement"> 
                      <p>{{ $t('form.label.from') }}: {{ formatDate(item.start) }}</p>
                    </div>
                    <div class="col-md-6 cardContentElement"> 
                      <p>{{ $t('form.label.to') }}: {{ formatDate(item.end) }}</p>
                    </div>
                    <div class="col-md-6 cardContentElement"> 
                      <p>{{ $t('form.label.street') }}: {{ item.location.street }}</p>
                    </div>
                    <div class="col-md-6 cardContentElement"> 
                      <p>{{ $t('form.label.city') }}: {{ item.location.city }}</p>
                    </div>
                    <div class="col-md-6 cardContentElement"> 
                      <p>{{ $t('form.label.type') }}: {{ item.location.type.name }}</p>
                    </div>
                    <div class="col-md-6 cardContentElement"> 
                      <p v-if="item.price_model === 'fix'">{{ $t('form.label.priceModel') }}: Fixpreis</p>
                      <p v-if="item.price_model === 'auction'">{{ $t('form.label.priceModel') }}: Auktion</p>
                    </div>
                    <div class="col-md-6 cardContentElement"> 
                      <p>{{ $t('form.label.region') }}: {{ item.location.region.name }}</p>
                    </div>
                    <div class="col-md-6 cardContentElement"> 
                      <p v-if="item.repead === 'no'" >{{ $t('form.label.repead') }}: Einmalig</p>
                      <p v-if="item.repead === 'daily'" >{{ $t('form.label.repead') }}: Täglich</p>
                      <p v-if="item.repead === 'weekly'" >{{ $t('form.label.repead') }}: Wöchentlich</p>
                      <p v-if="item.repead === 'monthly'" >{{ $t('form.label.repead') }}: Monatlich</p>
                    </div> 
                    <div class="col-md-6 cardContentElement" v-if="item.price_model === 'auction'"> 
                      <p>{{ $t('form.label.bids') }}: {{ item.bids }}</p>
                    </div>  
                    <div class="col-md-6 cardContentElement" v-if="item.price_model === 'auction'"> 
                      <p>{{ $t('form.label.auctionEnd') }}: {{ auctionEndCountdown(item.auction_end, item)}}</p>
                     
                    </div>  
                  </div>
                  <!-- OFFERT CONTENT END -->
                </div>
                <div class="card-footer align-self-end justify-content-end d-flex" style="width:100%">
                 
                  <button class="btn btn-primary btn-sm" v-if="item.status === 'gebucht'  && checkExpired(item.start) === false && statusUpdateLoading === false" @click="statusUpdate(item, 'bestätigt')">{{ $t('button.bookingConfirm') }}</button>
                  <button class="btn btn-primary btn-sm" :disabled="true" v-if="item.status === 'gebucht'  && checkExpired(item.start) === false && statusUpdateLoading === true">
                    <span class="spinner-border spinner-border-sm" aria-hidden="true" style="margin-right:5px"></span>
                    <span role="status">{{ $t('button.bookingConfirm') }}</span>

                  </button>
                  <button type="button" class="btn btn-secondary btn-sm" @click="$router.push('/truck/'+item.truck)" v-if="item.status === 'gebucht' || item.status === 'bestätigt'" style="margin-left:10px;"><font-awesome-icon icon="fa-solid fa-truck-front" style="margin-right:0" /></button>
                  <a  class="btn btn-secondary btn-sm d-flex align-items-center" :href="'mailto:'+item.email_buyer" v-if="item.status === 'bestätigt' && item.buyer" style="margin-left:10px;"><font-awesome-icon icon="fa-solid fa-envelope" style="margin-right:0" /></a>
                  
                  <!-- FUNCTION DROPDOWN -->
                  <div class="btn-group dropup">
                    <div  class="footerFunctions" data-bs-toggle="dropdown" aria-expanded="false" >
                      <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" /> 
                    </div>
                    <div class="dropdown-menu " style="padding:0; width:fit-content;white-space: nowrap;"> 
                      <ul class="list-group list-group-flush" style="border-radius: 5px; ">
                        <template v-if="item.status === 'gebucht' || item.status === 'bestätigt'">
                          <a :class="{'list-group-item': true, 'list-group-item-action': true,}" href="#" @click.prevent="$router.push('/truck/'+item.truck)">
                          <font-awesome-icon icon="fa-solid fa-eye"  />
                          {{ $t('button.viewTruck') }}
                          </a>
                        </template>
                        <template v-if="item.status === 'gebucht'  && checkExpired(item.start) === false">
                          <a :class="{'list-group-item': true, 'list-group-item-action': true,}" href="#" @click="statusUpdate(item, 'bestätigt')" >
                          <font-awesome-icon icon="fa-solid fa-circle-check"  />
                          {{ $t('button.bookingConfirm') }}
                          </a>
                          <a :class="{'list-group-item': true, 'list-group-item-action': true,}" href="#" @click.prevent="cancelBooking(item)" >
                          <font-awesome-icon icon="fa-solid fa-ban"  />
                          {{ $t('button.bookingCancel') }}
                          </a>
                        </template>
                        <template v-if="item.status === 'offen' || item.status === 'keine gebote' ">
                          <a :class="{'list-group-item': true, 'list-group-item-action': true,}" href="#"  @click.prevent="deleteBookingModal.show(); deleteItem = item">
                            <font-awesome-icon icon="fa-solid fa-trash"  />
                            {{ $t('button.delete') }}
                          </a>
                      </template>
                      </ul>
                    </div>
                  </div>
                  <!-- FUNCTION DROPDOWN END -->
                </div>
              </div>
            </div>
          </div>
          <!-- CARD OFFERT END -->
       </div>
       <!-- PAGINATION NAV -->
       <nav aria-label="Page navigation" v-if="paginatedOfferts.length > 0">
          <ul class="pagination">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <a class="page-link" href="#" @click="changePage(currentPage - 1)">Zurück</a>
            </li>
            <li class="page-item" v-for="(page, x) in totalPages" :key="x" :class="{ active: currentPage === page }">
              <a class="page-link" href="#" @click="changePage(page)">{{ page }}</a>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
              <a class="page-link" href="#" @click="changePage(currentPage + 1)">Vorwärts</a>
            </li>
          </ul>
        </nav>
        <!-- PAGINATION NAV END -->
      </div>
      <!-- CANCEL BOOKING MODAL -->
      <div class="modal" id="cancelBookingModal"  >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ $t('modal.title.bookingCancel') }}</h5>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12" style="margin-bottom:0 !important">
                  <!-- REASON --> 
                 
                    <div class="form-floating mb-3">
                      <textarea :class="{'form-control': true, 'is-invalid': errors.reason}" id="cancelReasonField" v-model="cancelReason" placeholder="Beschreibung" style="height: 150px"></textarea>
                      <label for="cancelReasonField" class="form-label" >{{ $t('form.label.reason') }}</label>
                      <div class="invalid-feedback" v-if="errors.reason">
                        <p v-for="(item, x) in errors.reason" :key='x' >{{ errors.reason[x] }}</p>
                      </div>
                    
                    <p>Min. 20, max. 200 Zeichen <span :class="{'text-danger': isCancelReasonValid() === false}" >({{ cancelReason.length }}/20-200)</span></p>
                  </div>
                  <!-- REASON END -->    
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="cancelBookingModal.hide();cancelItem = null;cancelReason=''">{{ $t('button.cancel') }}</button>
              <button type="button" class="btn btn-primary" :disabled="isCancelReasonValid() === false" @click="statusUpdate(cancelItem, 'offen');cancelBookingModal.hide();cancelItem = null;cancelReason=''">{{ $t('button.bookingCancel') }}</button>
            </div>
          </div>
        </div>
      </div>
      <!-- CANCEL BOOKING MODAL END -->
      <!-- DELETE BOOKING MODAL -->
      <div class="modal" id="deleteBookingModal"  >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ $t('modal.title.deleteOffert') }}</h5>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12" style="margin-bottom:0 !important">
                  <!-- WARNING --> 
                    <p v-if="deleteItem && deleteItem.group">{{ $t('modal.text.deleteGroup') }}</p>
                    <p v-else>{{ $t('modal.text.deleteBooking') }}</p>
                  <!-- WARNING END -->    
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="deleteBookingModal.hide();deleteItem = null">{{ $t('button.cancel') }}</button>
              <template v-if="deleteItem && deleteItem.group">
                <button type="button" class="btn btn-danger" @click="deleteOffert(deleteItem, 'single');deleteBookingModal.hide();deleteItem = null">{{ $t('button.deleteSingle') }}</button>
                <button type="button" class="btn btn-danger" @click="deleteOffert(deleteItem, 'multible');deleteBookingModal.hide();deleteItem = null">{{ $t('button.deleteGroup') }}</button>
              </template>
              <template v-else>
                <button type="button" class="btn btn-danger" @click="deleteOffert(deleteItem, 'single');deleteBookingModal.hide();deleteItem = null">{{ $t('button.delete') }}</button>
              </template>
            </div>
          </div>
        </div>
      </div>
      <!-- DELETE BOOKING MODAL END -->
    </div>
</template>
<script>
  import LocationsDropdown from "@/components/LocationsDropdownSingle.vue"
  import flatPickr from 'vue-flatpickr-component';
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import timezone from 'dayjs/plugin/timezone';
  import { MapboxMap, MapboxMarker, MapboxNavigationControl  } from '@studiometa/vue-mapbox-gl'
  import { useIntervalFn } from '@vueuse/core';
  import RegionDropdown from '@/components/RegionDropdownV2extended.vue';
  import MultiDropdown from '@/components/MultiDropdownGeneric.vue';
  import { Modal } from "bootstrap";
  import { Tooltip } from 'bootstrap';




dayjs.extend(utc);
dayjs.extend(timezone);

  export default {

    name: '${COMPONENT_NAME}',
    components: {
      LocationsDropdown,
      flatPickr,
      MapboxMap,
      MapboxMarker,
      MapboxNavigationControl,
      RegionDropdown,
      MultiDropdown,
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      
      userData:{required: true},
      apiData:{type: Function},
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        filterActions:0,
        statusUpdateLoading:false,
        addOffertLoading:false,
        cancelReason:'',
        cancelItem:null,
        deleteItem:null,
        hasLocations:false,
        itemsPerPage: 10,           // Anzahl der Items pro Seite
        currentPage: 1,             // Aktuelle Seite
        bookedOfferts:0,
        selectedStatus:'',
        loading:true,
        currentTime: dayjs(), // Reaktiver Wert für die aktuelle Zeit
        auctionPossible:false,
        range:'',
        startConfig:{
          dateFormat: 'd.m.Y H:i',
          enableTime: true,
          time_24hr:true,
          altInput: false, // Wichtig: Verhindert die Verwendung eines separaten Anzeige-Inputs
          defaultDate: null, // Kein Standarddatum, um das Floating Label korrekt zu behandeln
          minDate: new Date().fp_incr(2)
          
        }, 
        endConfig: {
          dateFormat: 'd.m.Y H:i', // Format für Enddatum
          enableTime: true,
          time_24hr:true,
          altInput: false,
          defaultDate: null,
          minDate: null, // Wird dynamisch an das Startdatum angepasst
        }, 
        repeadToConfig: {
          dateFormat: 'd.m.Y H:i', // Format für Enddatum
          enableTime: true,
          time_24hr:true,
          altInput: false,
          defaultDate: null,
          minDate: null, // Wird dynamisch an das Startdatum angepasst
        }, 
        errors:{},
        offert:{
          seller:this.userData.uuid,
          location:null,
          start:null,
          end:null,
          repead:'no',
          repeadTo:null,
          priceModel:'fix',
          auctionEnd:null,
          price:null,
          steps:null,
        },
        mainFilter:{
          von:null,
          bis:null,
          region:[],
          type:[],
          priceModel:[],
          maxPrice:null,
          hideExpired:false
        },
        filterPickerStart:{
          dateFormat: 'd.m.Y H:i',
          enableTime: true,
          time_24hr:true,
          altInput: false, // Wichtig: Verhindert die Verwendung eines separaten Anzeige-Inputs
          defaultDate: null, // Kein Standarddatum, um das Floating Label korrekt zu behandeln
          minDate: new Date().fp_incr(0)
          
        }, 
        filterPickerEnd:{
          dateFormat: 'd.m.Y H:i',
          enableTime: true,
          time_24hr:true,
          altInput: false, // Wichtig: Verhindert die Verwendung eines separaten Anzeige-Inputs
          defaultDate: null, // Kein Standarddatum, um das Floating Label korrekt zu behandeln
          minDate: new Date().fp_incr(0)
          
        }, 
        dropdownTypeData:[
          {id:315 , name:'Parkplatz', value:'Parkplatz'},
          {id:316, name:'Strasse', value:'Strasse'},
          {id:317, name:'Event', value:'Event'},
          {id:318, name:'Festival', value:'Festival'},
          {id:319, name:'Baustelle', value:'Baustelle'},
          {id:320, name:'Einkaufszentrum', value:'Einkaufszentrum'},
          {id:321, name:'Ladenlokal', value:'Ladenlokal'},
        ],
        dropdownPricemodelData:[
          {name:'Aukton', value:'auction'},
          {name:'Fixpreis', value:'fix'},
        ],
        offerts:[]
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {
      mapboxTheme(){
        let value
        if(localStorage.getItem('theme') === 'light'){
          value ='mapbox://styles/mapbox/light-v11'
        }
        if(localStorage.getItem('theme') === 'dark'){
          value ='mapbox://styles/mapbox/dark-v11'
        }
        return value;
      },
      filteredOfferts() {
      if (this.selectedStatus) {
        // Wenn ein Status ausgewählt ist, filtere nach diesem Status
        return this.offerts.filter(item => item.status === this.selectedStatus);
      } else {
        // Wenn kein Filter ausgewählt ist, zeige alle Elemente
        // Filter abgelaufene Angebote
        
        return this.offerts;
      }
      
    },
    filteredOffertsDetail() {
      let offerts = this.filteredOfferts;

      // Datum: Filtere nach 'von' und 'bis'
      if (this.mainFilter.von || this.mainFilter.bis) {
        const von = this.mainFilter.von ? this.parseFilterDate(this.mainFilter.von) : null;
        const bis = this.mainFilter.bis ? this.parseFilterDate(this.mainFilter.bis) : null;

        offerts = offerts.filter(item => {
          const start = this.convertUTCToLocal(item.start);

          if (von && bis) {
            return start.isSameOrAfter(von) && start.isSameOrBefore(bis);
          }

          if (von) {
            return start.isSameOrAfter(von);
          }

          if (bis) {
            return start.isSameOrBefore(bis);
          }

          return true;
        });
      }

      // Preisfilter: Vergleich von 'maxPrice' unter Berücksichtigung von 'bid' und 'steps'
      if (this.mainFilter.maxPrice !== null) {
        offerts = offerts.filter(item => {
          if (item.bid !== null) {
            const bidWithSteps = parseFloat(item.bid) + (item.steps ? parseFloat(item.steps) : 0);
            return bidWithSteps <= this.mainFilter.maxPrice;
          } else {
            return parseFloat(item.price) <= this.mainFilter.maxPrice;
          }
        });
      }

      // Filter nach Region
      if (this.mainFilter.region.length > 0) {
        const regionIds = this.mainFilter.region.map(region => region.id);
        offerts = offerts.filter(item => regionIds.includes(item.location.region.id));
      }

      // Filter nach Type
      if (this.mainFilter.type.length > 0) {
        const typeIds = this.mainFilter.type.map(type => type.id);
        offerts = offerts.filter(item => typeIds.includes(item.location.type.id));
      }

      // Filter nach Price Model
      if (this.mainFilter.priceModel.length > 0) {
        const priceModels = this.mainFilter.priceModel.map(model => model.value);
        offerts = offerts.filter(item => priceModels.includes(item.price_model));
      }

      // Filter abgelaufene Angebote
      if (this.mainFilter.hideExpired) {
        offerts = offerts.filter(item => !this.checkExpired(item.start));
      }

      return offerts;
    },
    // Berechnet die totalen Seiten basierend auf der Anzahl der Daten
    
    totalPages() {
      return Math.ceil(this.filteredOffertsDetail.length / this.itemsPerPage);
    },
    // Schneidet die Daten für die aktuelle Seite aus
    
    paginatedOfferts() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredOffertsDetail.slice(start, end);
    }

    },
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {
      $route(){
        if(this.$route.params.view === 'booked'){
        this.selectedStatus ='gebucht';
        }
        if(this.$route.params.view === 'confirmed'){
          this.selectedStatus ='bestätigt';
        }
      },
      mainFilter: {
        handler(newValue) {  
          if (newValue.von) {
            this.filterPickerEnd.minDate = newValue.von;
            //console.log('filterende gesetzt');
          }
          if (newValue.von || newValue.bis || newValue.region || newValue.type || newValue.priceModel || newValue.maxPrice || newValue.hideExpired) {
            localStorage.setItem('offertsFilter', JSON.stringify(this.mainFilter));
            //console.log('filtererer')
            if(this.filterActions > 0)
            {
             this.currentPage = 1;
            }
            this.filterActions ++;
          }
        },
        deep: true // Tiefes Watching aktivieren
      },
      currentPage(){
        localStorage.setItem('offertsPage', this.currentPage);
      },

      hasLocations(val) {
      if (val && !this.loading) {
        this.initializeTooltips();
      }
    },
    loading(val) {
      if (!val && this.hasLocations) {
        this.initializeTooltips();
      }
    },
    auctionPossible() {
      this.updateTooltip();
    },
    'offert.end'() {
      this.updateTooltip();
    },
     
    },
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    async beforeMount() {
     


      const offertsFilter = localStorage.getItem('offertsFilter');
        if (offertsFilter) {
            const parsedFilter = JSON.parse(offertsFilter);
            this.mainFilter = parsedFilter;
        }
      const offertsPage = localStorage.getItem('offertsPage');
        if (offertsPage) {
           
            this.currentPage = parseInt(offertsPage, 10);
        }
       this.getOfferts();
       this.getLocations();
    },
         
          // render(h) { return h(); },
     async mounted() {
      
      this.initializeTooltips(); 
         this.cancelBookingModal = new Modal(document.getElementById("cancelBookingModal"),{ keyboard: false });
         this.deleteBookingModal = new Modal(document.getElementById("deleteBookingModal"),{ keyboard: false });
    useIntervalFn(() => {
      this.currentTime = dayjs(); // Aktualisiere die aktuelle Zeit
    }, 1000);
    useIntervalFn(() => {
      this.getOfferts();
    }, 60000);
    if(this.$route.params.view === 'booked'){
        this.selectedStatus ='gebucht';
      }
      if(this.$route.params.view === 'confirmed'){
        this.selectedStatus ='bestätigt';
      }
        
    },
   
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      async getContact(uuid){
        let request = {
          uuid: uuid
        };
        let response;
        response = await this.apiData('OffertsView.vue getContact()','post','get-contact', request);
        //console.log(response);
        return response.email;
      },
      initializeTooltips() {
        this.$nextTick(() => {
          document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach((tooltipTriggerEl) => {
            const tooltipInstance = Tooltip.getInstance(tooltipTriggerEl);
            if (tooltipInstance) {
              tooltipInstance.dispose();
            }
            new Tooltip(tooltipTriggerEl, {
              customClass: 'custom-tooltip', // Falls du custom CSS verwendest
              container: 'body'              // Optional: in den globalen Body-Container verschieben
            });
          });
        });
      },
      updateTooltip() {
      // Tooltip neu initialisieren oder entfernen, basierend auf der Bedingung
      if (!this.auctionPossible && !this.tooltipInstance) {
        this.initializeTooltips();
      } else if (this.tooltipInstance) {
        this.tooltipInstance.dispose();
        this.tooltipInstance = null;
      }
    },
  
      isCancelReasonValid() {
        if(this.cancelReason.length >= 20 && this.cancelReason.length <= 200){
          return true;
        }else{
          return false;
        }
      },
      cancelBooking(cancelItem){
        this.cancelBookingModal.show();
        this.cancelItem = cancelItem;
      },
      async getLocations(){
        this.loading = true;
        let request = {
          selectorTable:'owner',
          selectorValue: this.userData.uuid
        };
        let response;
        response = await this.apiData('ProfileView.vue getLocations()','post','get-locations', request);
        if(response.length >0){
          this.hasLocations = true;
        }else{
          this.hasLocations = false;
        }
        this.loading = false;
       
      },
      // Seitenwechsel
      changePage(page) {
        if (page > 0 && page <= this.totalPages) {
          this.currentPage = page;
        }
      },
      formatDate(dateString) {
        return dayjs.utc(dateString).local().format('DD.MM.YY HH:mm');
      },
      /* ###################### OFFERT DB ROUTINES ###################### */
       /* ******** STATUS UPDATE ******** */
       async statusUpdate(item, status){
        this.statusUpdateLoading = true;
        let request = {
          selectorTable:'id',
          selectorValue: item.id,
          newStatus: status,
          buyer: item.buyer,
          start: this.formatDate(item.start),
          end: this.formatDate(item.end),
          location:item.location.name,
          item:item,
          seller:item.seller,
          cancelReason: this.cancelReason
        };
        //console.log(request);
        let response;
        response = await this.apiData('OffertsView.vue statusUpdate()','post','status-update', request);
        if (response === 'success'){
          this.getOfferts();
          if(status === 'bestätigt'){
            this.$toast.open({
            message: this.$t('toast.bookingConfirm'),
            type: 'success',
            duration:3000,
            });
          }
          if(status === 'offen'){
            this.$toast.open({
            message: this.$t('toast.bookingCancel'),
            type: 'warning',
            duration:3000,
            });
          }
          
        }
        //console.log(response); 
        this.statusUpdateLoading = false;
       },
       /* ******** GET OFFERTS ******** */
       async getOfferts(){
        
        let request = {
          selectorTable:'seller',
          selectorValue: this.userData.uuid
        };
        let response;
        response = await this.apiData('LocationView.vue getOfferts()','post','get-offerts', request);
        this.offerts = response;
        this.bookedOfferts = 0;
        for(let i = 0; i < response.length; i++){
          if(response[i].status === 'gebucht'){
            this.bookedOfferts ++;
          }
          
        }
        
      },
       /* ******** GET OFFERTS END ******** */
       /* ******** ADD OFFERTS ******** */
      async addOffert(){
        this.addOffertLoading = true;
        let response;
        response = await this.apiData('LocationsView.vue addOffert()','post','add-offert', this.offert);
        if (response.status === 'failure'){
        this.errors = response.errors;
        this.$toast.open({
          message: this.$t('toast.error'),
          type: 'error',
          duration:3000,
        });
        }else{
          this.getOfferts();
          this.errors = {};
          this.$toast.open({
            message: this.$t('toast.addOffert'),
            type: 'success',
            duration:3000,
          });
        }  
        console.log(response);
       
          this.offert.seller=this.userData.uuid;
          this.offert.start=null;
          this.offert.end=null;
          this.offert.repead='no';
          this.offert.repeadTo=null;
          this.offert.priceModel='fix';
          this.offert.auctionEnd=null;
          this.offert.price=null;
          this.offert.steps=null;
        
        //this.setStart();
        //this.setEnd(); 
        this.$refs.startPicker.fp.clear();
        this.$refs.endPicker.fp.clear();    
        this.addOffertLoading = false; 
      },
      /* ******** ADD OFFERTS END ******** */
      /* ******** DELETE OFFERTS ******** */
      async deleteOffert(item, type){
        //console.log(item);
        //console.log(type);
        item.deleteType = type;
        let query =  await this.apiData('OffertsView.vue deleteOffert)','post','delete-offert', item);
        this.getOfferts();
        console.log(query); //Unused Variable
        if(type === 'single')
        {
          this.$toast.open({
          message: this.$t('toast.bookingDelete'),
          type: 'success',
          duration:3000,
          });
        }
        if(type === 'multible')
        {
          this.$toast.open({
          message: this.$t('toast.bookingsDelete'),
          type: 'success',
          duration:3000,
          });
        }
      
      },
      /* ******** DELETE OFFERTS ******** */
      /* ###################### OFFERT DB ROUTINES END ###################### */
      /* ###################### REPEAD CONFIG ###################### */
      setRepeadToConfig() {
    // Setze den Wert von 'this.offert.repeadTo' auf null
        this.offert.repeadTo = null;
        const startDate = new Date(this.offert.start);
        if (!startDate || isNaN(startDate.getTime())) {
          //console.error('Ungültiges Startdatum');
          return;
        }
        switch (this.offert.repead) {
          case 'daily': {
            // Plus 24 Stunden
            this.repeadToConfig.minDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000); 
            break;
          }
          case 'weekly': {
            // Plus 1 Woche
            this.repeadToConfig.minDate = new Date(startDate.getTime() + 7 * 24 * 60 * 60 * 1000);
            break;
          }
          case 'monthly': {
            // Plus 1 Monat (verwende setMonth für Datumsanpassung)
            const nextMonth = new Date(startDate);
            nextMonth.setMonth(startDate.getMonth() + 1);
            this.repeadToConfig.minDate = nextMonth;
            break;
          }
          default:
        }
      },
      /* ###################### REPEAD CONFIG END ###################### */
      /* ###################### SET END DATE ###################### */
      setEnd(selectedDates) {
        this.offert.priceModel='fix';
        this.offert.repeadTo=null;
        this.offert.repead='no';
        const endDate = selectedDates.length ? new Date(selectedDates[0]) : null;
        if (this.offert.start) {
          // Speichere Startdatum in der Offert-Objekt
          this.offert.end = endDate;
          this.calculateRange(selectedDates);
        }
      },
      /* ###################### SET END DATE END ###################### */
      /* ###################### SET START DATE ###################### */
      setStart(selectedDates) {
        this.offert.priceModel = 'fix';
        this.offert.repeadTo = null;
        this.offert.repead = 'no';
        // Konvertiere ausgewähltes Datum in ein gültiges Date-Objekt
        const startDate = selectedDates.length ? new Date(selectedDates[0]) : null;
        if (startDate) {
            // MinDate für Enddatum festlegen
            this.endConfig = {
                ...this.endConfig,
                minDate: startDate,
            };
            // Speichere Startdatum in der Offert-Objekt
            this.offert.start = startDate;
            // Berechne die Zeitdifferenz zwischen heute und dem Startdatum (in Stunden)
            const now = new Date();
            const timeDiff = (startDate - now) / (1000 * 60 * 60); // Zeitdifferenz in Stunden
            // Prüfe, ob die Differenz mindestens 72 Stunden beträgt
            if (timeDiff >= 72) {
                this.auctionPossible = true;
                // Setze das Enddatum für die Auktion auf exakt 48 Stunden vor dem Startdatum
                const auctionEndDate = new Date(startDate.getTime() - (48 * 60 * 60 * 1000)); // 48 Stunden in Millisekunden
                this.offert.auctionEnd = auctionEndDate;
            } else {
                this.auctionPossible = false;
                this.offert.auctionEnd = null;
            }
            // Wenn ein Enddatum gesetzt ist, berechne den Bereich
            if (this.offert.end) {
                this.calculateRange(this.offert.end);
            }
        }
    },
    /* ###################### SET START DATE END ###################### */
    /* ###################### RANGE FOR REPEAD INTERVAL ###################### */
    calculateRange(selectedDates) {
      if (selectedDates.length === 0) return;
      let endDate = null;
      if(!this.offert.end){
        endDate = new Date(selectedDates[0]);
      }else{
        endDate = this.offert.end;
      }   
      const startDate = this.offert.start;
      if (startDate && endDate) {
        const diffTime = endDate.getTime() - startDate.getTime(); // Differenz in Millisekunden
        const diffHours = diffTime / (1000 * 60 * 60); // Umwandlung in Stunden
        if (diffHours < 24) {
          this.range = 'hour';
        } else if (diffHours >= 24 && diffHours < 168) {
          this.range = 'day'; // 1 Tag bis weniger als 1 Woche
        } else if (diffHours >= 168 && diffHours < 720) {
          this.range = 'week'; // 1 Woche bis weniger als 1 Monat
        } else if (diffHours >= 720) {
          this.range = 'month'; // 1 Monat oder mehr
        }
      } else {
        this.range = '';
      }
    },
    /* ###################### RANGE FOR REPEAD INTERVAL END ###################### */
     /* ###################### AUCTION TIMER ###################### */
     auctionEndCountdown(targetDateString, item) {
        // Parse das Datum als UTC und konvertiere in lokale Zeit
        const targetDate = dayjs.utc(targetDateString).local();
        const timeDiff = targetDate.diff(this.currentTime);

        if (timeDiff > 0) {
          const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

          // Füge führende Nullen hinzu, wenn die Zahl einstellig ist
          const formattedDays = String(days).padStart(2, '0');
          const formattedHours = String(hours).padStart(2, '0');
          const formattedMinutes = String(minutes).padStart(2, '0');
          const formattedSeconds = String(seconds).padStart(2, '0');

          return `${formattedDays}:${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        } else {
          if(item.auction_closed === 0){
            this.closeAuction(item);
          }
          return 'Auktion abgelaufen';
        }
      },
      async closeAuction(item){
      
            let response;
            response = await this.apiData('BookView.vue auctionEndCountdown()','post','close-auction', item);
            console.log(response);
            this.getOfferts();
          
      },
    /* ###################### AUCTION TIMER END ###################### */  
    checkExpired(dateString) {
      // Umwandlung der übergebenen Zulu-Zeit in ein Ortszeit-Datum
      const inputDate = dayjs.utc(dateString).local();
      
      // Vergleich mit dem aktuellen Datum und Uhrzeit in Ortszeit
      return inputDate.isAfter(dayjs()) ? false : true;
    },
    removeFilterBadge(filter, x){
        if(filter === 'von'){
          this.mainFilter.von = null;
        }
        if(filter === 'bis'){
          this.mainFilter.bis = null;
        }
        if(filter === 'region'){
          this.mainFilter.region.splice(x, 1);
        }
        if(filter === 'type'){
          this.mainFilter.type.splice(x, 1);
        }
        if(filter === 'priceModel'){
          this.mainFilter.priceModel.splice(x, 1);
        }
        if(filter === 'maxPrice'){
          this.mainFilter.maxPrice = null;
        }
      },
      parseFilterDate(dateString) {
        const format = 'DD.MM.YYYY HH:mm'; // Verwende das strikte TT.MM.JJJJ-Format
        return dayjs(dateString, format, true); // Striktes Parsing
      },
      convertUTCToLocal(dateString) {
        return dayjs.utc(dateString).local(); // Konvertiere von UTC in lokale Zeit
      },
    },
    
  };
</script>
<style lang="scss">

.footerFunctions{
  padding: 0 10px 0 20px;cursor: pointer;
  font-size: 24px;
}




</style>