<template>
    <div class="container  py-4">
      <div class="row">
        <div class="col-md-3">
          <select class="form-select" aria-label="Default select example" id="priceModelField"  
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title="Tooltip oben"
      disabled
      >
                      <option value="fix" selected>Fixpreis</option>
                      <option value="auction" >Auktion</option>
                    </select>
        </div>
      </div>
      <select class="form-select" aria-label="Default select example" id="priceModelField"  
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title="Tooltip oben"
      disabled
      >
                      <option value="fix" selected>Fixpreis</option>
                      <option value="auction" >Auktion</option>
                    </select>
      <button class="btn btn-primary" @click="isolateUUID()"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title="Tooltip oben"
      >UUID Isolieren</button>
      <button class="btn btn-primary" @click="getCoordinatesFromAddress()">Koordinaten von Adresse</button>
    </div>
</template>
<script>
  import axios from 'axios';
  import { Tooltip } from 'bootstrap';
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      apiData: {type: Function},
      userData:{required: true}
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    async mounted() {
      this.initTooltips();
    },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: { 
      initTooltips() {
      // Tooltips direkt über das `document` initialisieren
      document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach((tooltipTriggerEl) => {
        new Tooltip(tooltipTriggerEl);  // Tooltip-Instanz erstellen
      });
    },
      async isolateUUID(){
        let request = {
          //uuid:this.userData.uuid,
        };
        let response;
        response = await this.apiData('SandboxView.vue isolateUUID()','post','isolate-uuid', request);
        console.log(response);
      },
      getCoordinatesFromAddress() {
        const request = {
          street: 'gutstrasse 175',
          zip: '8047',
          city: 'zürich',
          country: 'ch',
        };

        // Erstellen der Adresse als Suchtext
        const address = `${request.street}, ${request.zip} ${request.city}, ${request.country}`;
        
        // Geocode Earth API URL für Forward Geocoding
        const apiUrl = `https://api.geocode.earth/v1/search?text=${address}&api_key=ge-ef6d511751d04497`;
        

        axios
          .get(apiUrl)
          .then((response) => {
            // Hier kannst du die Koordinaten extrahieren
            if (response.data.features && response.data.features.length > 0) {
              //const coordinates = response.data.features[0].geometry.coordinates;
              console.log('Koordinaten:', response.data);
            } else {
              console.log('Keine Koordinaten für diese Adresse gefunden.');
            }
          })
          .catch((error) => {
            console.error('Fehler bei der Geocoding-Anfrage:', error);
          });
      },
    },
  };
</script>
<style lang="scss">
  
</style>