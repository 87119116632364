<template>
  <div class="container  py-4">
  <div class="row d-flex justify-content-center" style="margin-top: auto; margin-bottom: auto; ">
    <div class="row">
      <div class="col-md-6">
        <h2>Haftungsausschluss</h2>
        <p>Die Inhalte dieser Website wurden mit größtmöglicher Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.</p>
        <h2>Urheberrecht</h2>
        <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf dieser Website unterliegen dem schweizerischen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.</p>
      </div>
      <div class="col-md-6">
        <h2>Impressum</h2>
        <p><strong>Foodtruck Locator</strong> ist ein Produkt von <strong>White Rabbit Studios</strong>.</p>
        <p>White Rabbit Studios - Sascha Bencze</p>
        <p>Dorfstrasse 29<br>
          8966 Oberwil-Lieli<br>
          Schweiz</p>
        <p><strong>Kontakt:</strong><br>
          Telefon: +41 79 127 09 19<br>
          E-Mail: <a href="mailto:mail@foodtruck-locator.ch">mail@foodtruck-locator.ch</a></p>
      </div>
    </div>
  </div>
</div>
</template>


<script>
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>