<template>
    <div class="container  py-4"> 
      
      <div v-if="loading === false">
        <!-- TRUCKS HEADER -->
        <div class="row d-flex">
          <div class="col-md-1">
            <button class="btn btn-outline-secondary h-100 backBtn" style="width:100%" @click="$router.go(-1)"> <font-awesome-icon icon="fa-solid fa-chevron-left"  /></button>
          </div>
          <div class="col-md-11">
            <div class="card text-bg-card-header">
              <div class="card-body">
                <div class="row d-flex align-items-center">
                  <div class="col-md-1 headerLogo"  :style="{ backgroundImage:'url('+config.storageURL+owner.owner+'/img/'+owner.logo+')', 'height':'40px'}">
                  </div>
                  <div class="col-md-11" style="margin-bottom:0 !important;">
                    <h4>{{owner.company}} | {{truck.name}}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- TRUCKS HEADER END -->
        <div class="row  d-flex">
          <!-- CARD DETAILS -->
          <div class="col-md-7">
              <div class="card" style="margin-bottom: 20px;">
                <div class="card-header">
                  <h5>{{ $t('card.title.provider') }}</h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">{{ $t('form.label.company') }}</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0">{{ owner.company }}</p>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">{{ $t('form.label.street') }}</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0">{{ owner.street }}</p>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">{{ $t('form.label.address') }}</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0">{{ owner.zip }} {{ owner.city }}</p>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">{{ $t('form.label.country') }}</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0">{{ owner.country }}</p>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">{{ $t('form.label.phone') }}</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0">{{ owner.phone }}</p>
                    </div>
                  </div>
                  <hr v-if="owner.url">
                  <div class="row" v-if="owner.url">
                    <div class="col-sm-3">
                      <p class="mb-0">{{ $t('form.label.website') }}</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0">{{ owner.url }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header"><h5>{{ $t('form.label.categories') }}</h5></div>
                <div class="card-body">
                  <span class="badge bg-secondary" v-for="(categorie, z) in truck.categories" :key='z' style="margin-right:5px">{{categorie.name}}</span>&nbsp;
                </div>
              </div>
            </div>
            <!-- CARD DETAILS END -->
            <!-- GALLERY -->
            <div class="col-md-5" v-if="truck.images.length > 0">
              <div class="card h-100" style="min-height:400px">
                
                <div class="card-body d-flex flex-column">
                  <!-- BILD --> 
                  <div class="flex-grow-1 galleryImg" :style="{ backgroundImage:'url('+config.storageURL+owner.owner+'/img/'+gallery.img+')'}">
                   
                  </div>
                  <!-- BILD END --> 
                  <!-- TMB CONTAINER --> 
                  <div class="d-flex" >
                    <!-- SPLIDE BUTTON ZURÜCK --> 
                    <div  class="bg-secondary text-white d-flex justify-content-center align-items-center" style="width:30px; margin-right:1rem; border-radius: 5px 0 0 5px; cursor: pointer;" @click="splicerNav('<')">
                      <font-awesome-icon icon="fa-solid fa-chevron-left"/>
                    </div>
                    <!-- SPLIDE BUTTON ZURÜCK ENDE --> 
                    <!-- SPLIDE CONTAINER --> 
                    <div class="flex-grow-1 d-flex flex-column" style="min-width: 0;">          
                      <splide :options="splideOptions" ref="splicer" style="width: 100%; max-width: 100%;">
                        <splide-slide v-for="(item, x) in gallery.tmb" :key='x'>
                          <div :class="{'galleryTmbOuter':true, 'active': item.active}">
                            <div :class="{'galleryTmb':true, 'active': item.active}" :style="{ backgroundImage:'url('+config.storageURL+owner.owner+'/img/'+item.name+')'}" @click="tmbNav(item, x)"></div>
                          </div>
                        </splide-slide>
                      </splide>
                    </div> 
                    <!-- SPLIDE CONTAINER END --> 
                    <!-- SPLIDE BUTTON VOR -->  
                    <div class="bg-secondary text-white d-flex justify-content-center align-items-center" style="width:30px;margin-left:1rem; border-radius:0 5px 5px 0; cursor: pointer;" @click="splicerNav('>')">
                      <font-awesome-icon icon="fa-solid fa-chevron-right"/>
                    </div> 
                    <!-- SPLIDE BUTTON VOR END --> 
                  </div>
                  <!-- TMB CONTAINER END --> 
                  
                  
                </div>
              </div>
            </div>
            <!-- IMAGE PLACEHOLDER -->
            <div class="col-md-5" v-else>
              <div class="card h-100">
                <div class="card-body d-flex truckCardImg flex-column">
                  <div  class="d-flex truckCardImg flex-column h-100" :style="{ backgroundImage: `url(${require('@/assets/default_truck.png')})` }">

                  </div>
                </div>
              </div>
            </div>
            <!-- IMAGE PLACEHOLDER END -->
            <!-- GALLERY END -->
            
          </div>
        <!-- MAP -->

        <div class="row d-flex">
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-header"><h5>{{ $t('form.label.locations') }}</h5></div>
              <div class="card-body" style="padding:0; max-height: 600px; overflow-y: auto;">
                <div v-if="markers.length === 0" style="margin:15px">
                  {{ $t('card.body.noLocations') }}
                </div>
                <div class="mapDetails list-group list-group-flush" v-else>
                  <template   v-for="(item, index) in markers" :key="index">
                    <a href="#" :class="{'list-group-item':true, 'list-group-item-action':true, 'active': activeMarker === index}" aria-current="true" @click.prevent="handleListClick(index, [item.long, item.lat])" >
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">{{ item.name }}</h5>
                        <small style="white-space: nowrap;">{{ formatDate(getNextDate(item.dates)) }}</small>
                      </div>
                      <p class="mb-1">{{ item.street }}, {{ item.zip }} {{ item.city }}, {{ item.region }}</p>
                    </a>
                </template>          
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="card h-100">
              <div class="card-body">
            
                <MapboxMap 
                  access-token="pk.eyJ1Ijoic2JlbmN6ZSIsImEiOiJjbTFiYXVrY3YwbWY0MmpzYm0wamc0bDhiIn0.R_6w0DsjbSGASOg2Snz8Ng"
                  @mb-created="initializeMap"
                  style="min-height: 600px"
                  :center="mapCenter"
                  :map-style="mapboxTheme"
                  :zoom="zoom"
                  :scrollZoom="false"
                >
                <MapboxNavigationControl position="top-left" :showCompass="false" />
                <!-- REGIONEN -->
                <template   v-for="(item, x) in mapOverlay" :key='x'>
                  <MapboxSource :id="'route'+x" :options="item.routeSource"></MapboxSource>
                  <MapboxLayer :id="'routesource'+x" :options="item.routeLayer" />
                  <MapboxLayer :id="'outline'+x" :options="item.routeLayerOutline" />
                </template> 
                <!-- REGIONEN END -->

               <!-- LOCATIONS -->
                  <template v-for="(item, x) in markers" :key="x">
                    <!-- Marker mit Popup aktiviert -->
                    <MapboxMarker :lng-lat="[item.long, item.lat]"  color="#f9b233" ref="mapMarkers"  > 
                    <template v-slot:popup>
                    <MapboxPopup :lng-lat="[item.long, item.lat]" v-if="activeMarker === x" @mb-close="onPopupClose(x)">
                      <h5 @click="$router.push('/location/'+item.id)">{{ item.name }}</h5>
                      <ul class="list-group list-group-flush">
                        <li v-for="(date, index) in item.dates" :key="index" class="list-group-item">
                          {{formatDate(date.start) }} - {{ formatDate(date.end) }}
                        </li>
                      </ul>
                    </MapboxPopup>
                      </template>
                      <template v-slot:default >
                      <div  
                        class="mapboxgl-marker mapboxgl-marker-anchor-center"
                        style="position: absolute; transform: translate(-50%, -100%);"
                        @click.stop="handleListClick(x, [item.long, item.lat])"
                        >
                        <svg display="block" height="41px" width="27px" viewBox="0 0 27 41"><defs><radialGradient id="shadowGradient"><stop offset="10%" stop-opacity="0.4"></stop><stop offset="100%" stop-opacity="0.05"></stop></radialGradient></defs><ellipse cx="13.5" cy="34.8" rx="10.5" ry="5.25" fill="url(#shadowGradient)"></ellipse><path fill="#f9b233" d="M27,13.5C27,19.07 20.25,27 14.75,34.5C14.02,35.5 12.98,35.5 12.25,34.5C6.75,27 0,19.22 0,13.5C0,6.04 6.04,0 13.5,0C20.96,0 27,6.04 27,13.5Z"></path><path opacity="0.25" d="M13.5,0C6.04,0 0,6.04 0,13.5C0,19.22 6.75,27 12.25,34.5C13,35.52 14.02,35.5 14.75,34.5C20.25,27 27,19.07 27,13.5C27,6.04 20.96,0 13.5,0ZM13.5,1C20.42,1 26,6.58 26,13.5C26,15.9 24.5,19.18 22.22,22.74C19.95,26.3 16.71,30.14 13.94,33.91C13.74,34.18 13.61,34.32 13.5,34.44C13.39,34.32 13.26,34.18 13.06,33.91C10.28,30.13 7.41,26.31 5.02,22.77C2.62,19.23 1,15.95 1,13.5C1,6.58 6.58,1 13.5,1Z"></path><circle fill="white" cx="13.5" cy="13.5" r="5.5"></circle></svg>
                      </div>
                    </template>
                    <!-- Popup-Inhalt END -->
                    </MapboxMarker>
                    
                    
                    
                  </template>
                  <!-- LOCATIONS END -->
                </MapboxMap>
              </div>
            </div>
          </div>
        </div>
        <!-- MAP END -->


       <!-- MENUCARDS -->
       <div class="row"  v-for="(item, x) in menuCard" :key='x'>
        <div class="col-md-12">
          <div class="card" >
            
            <template v-for="(categories, y) in item.categories" :key='y'>
              <div class="card-header d-flex w-100 align-items-center justify-content-between">
                <h5>{{ categories.name }}</h5>
              </div>

              <ul class="list-group list-group-flush">
                <li class="list-group-item"  v-for="(menucardItems, z) in categories.content" :key='z'  >
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{menucardItems.title}}</h5>
                    <h5>CHF {{menucardItems.price}}</h5>
                  </div>
                  <div class="d-flex w-100 justify-content-between align-items-end">
                    <p class="mb-1">{{menucardItems.text}}</p>
                      
                  </div>
                </li>
              </ul>
            </template>
            <div class="card-footer d-flex w-100 justify-content-between">
              <h6>{{ item.name }}</h6>
      
            </div>
          </div>
        </div>
       </div>
      <!-- MENUCARDS  END -->
      </div>
      <div v-else> Loading ...</div>

     


    </div>
</template>
<script>
//import mapboxgl from 'mapbox-gl'; 
   //import { ref } from 'vue';
  import { MapboxMap,  MapboxLayer,  MapboxSource, MapboxMarker, MapboxPopup, MapboxNavigationControl } from '@studiometa/vue-mapbox-gl';
  import { Splide, SplideSlide } from '@splidejs/vue-splide';
  import dayjs from 'dayjs';
  //import utc from 'dayjs/plugin/utc';
 
 // import 'mapbox-gl/dist/mapbox-gl.css';
  
  
  import '@splidejs/splide/dist/css/themes/splide-default.min.css';
  export default {
    name: '${COMPONENT_NAME}',
    components: {
      MapboxMap,
      MapboxLayer,
      MapboxSource,
      MapboxMarker,
      MapboxPopup,
      MapboxNavigationControl,
      Splide,
      SplideSlide
      
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      apiData:{type:Function},
      config:{requireed: true}
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        closingPopup: false,
        popups: {}, // Popup-Referenzen für jeden Marker
        activeMarker:null,
        markers:[],
        locations:[],
        splideOptions:{
          
          gap: '1rem',
          perPage: 1,
          //type:'loop',
          //autoWidth:true,
          arrows:false,
          width:'100%',
          fixedWidth:'120px',
          fixedHeight:'80px',
          pagination:false,

        },
        gallery:{
          img:'',
          tmb:[]
        },
        owner:null,
        decodetData:null,
        loading:true,
        mapCenter:[8.2311, 46.8083],
        mapCenterNeu:[],
        map: null,
        zoom:null,
        truck:null,
        mapOverlay:[],
        menuCard:[]
        
      
      }
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {
      mapboxTheme(){
        let value
        if(localStorage.getItem('theme') === 'light'){
          value ='mapbox://styles/mapbox/light-v11'
        }
        if(localStorage.getItem('theme') === 'dark'){
          value ='mapbox://styles/mapbox/dark-v11'
        }
        return value;
      }
    },
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    async beforeMount() {
      
    },
    // render(h) { return h(); },
    async mounted() {
       this.getTruck();
    },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      getNextDate(datesArray) {
      const today = dayjs(); // Aktuelles Datum
      let closestDate = null;

      // Gehe durch alle Daten und finde das nächste Datum in der Zukunft
      datesArray.forEach(item => {
        const startDate = dayjs(item.start); // Startzeitpunkt in Day.js
        if (startDate.isAfter(today)) {
          // Wenn kein Datum gesetzt ist oder das gefundene Datum näher an heute liegt, setze es
          if (closestDate === null || startDate.isBefore(closestDate)) {
            closestDate = startDate;
          }
        }
      });

      return closestDate ? closestDate.format('YYYY-MM-DD HH:mm:ss') : null;
    },
      
      
      handleListClick(x, position){


        if (this.activeMarker === x) {
        this.activeMarker = null; // Schließe das Popup, wenn derselbe Marker erneut geklickt wird
      } else {
        this.activeMarker = x; // Öffne das Popup für den neuen Marker
      }
      // Setze die Variable zurück, falls sie aktiv ist
      
        // Beende den Schließ-Timeout, wenn ein neues Popup geöffnet wird
        //this.activeMarker = x;
        this.map.flyTo({
          center: position, // LngLat-Koordinaten für das Zentrum
          essential: true, // Animation auf allen Geräten
          zoom: 10 // Optional: Zoom-Level festlegen
        });
      },
      onPopupClose(index) {
        if (this.activeMarker === index) {
        this.activeMarker = null;
      }
    },
    
      
      
      initializeMap(mapboxInstance) {
      // Überprüfe, ob die Mapbox-Instanz korrekt übergeben wird
     
      this.map = mapboxInstance; // Speichere die Mapbox-Instanz
      this.calculateMapCenterAndZoom(this.truck);
    },

      splicerNav(direction){
          this.$refs.splicer.go( direction );  
          

      },
      tmbNav(item, x){
        this.gallery.img = item.name;
        for(let i = 0; i < this.gallery.tmb.length; i++ ){
            this.gallery.tmb[i].active=false;
           
          }
          this.gallery.tmb[x].active = true;
      },
      
      async getTruck(){
        this.loading = true;
        //GET THE TRUCK
        let requestTruck = {
          selectorTable:'id',
          selectorValue: this.$route.params.id
        };
        let responseTruck;
        responseTruck = await this.apiData('TruckView.vue getTrucks()','post','get-trucks', requestTruck);
        this.truck = responseTruck[0];
       
        //BUILD THE REGION OBJECT
        let decodedData;
        for(let i = 0; i < this.truck.region.length; i++ ){
          decodedData = JSON.parse(this.truck.region[i].poligon);
          this.mapOverlay.push({
            routeLayer: {'id': "route"+i, 'type':"fill",  'source': "route"+i, 'paint': {'fill-color': '#f9b233', 'fill-opacity': 0.1 }}, 
            routeLayerOutline:{'id': "outline"+i,'type':"line",  'source': "route"+i,'paint': {'line-color': '#f9b233','line-width': 2}},
            routeSource:{'type': "geojson",'data': {'type': "Feature",'properties': {},'geometry': {'type': decodedData.type,'coordinates': decodedData.coordinates }}}
          });
        }
        //GET THE OWNER
        let requestUser = {
          selectorTable:'owner',
          selectorValue: this.truck.owner
        };
        let responseOwner;
        responseOwner = await this.apiData('TruckView.vue getTrucks()','post','get-user-details', requestUser);
        this.owner = responseOwner[0];

        //GET MENUCARD
        let requestMenucard = {
          selectorTable:'trucks',
          selectorValue: responseTruck[0].id
        };
        let responseMenucard;
        responseMenucard = await this.apiData('TruckView.vue getMenucards()','post','get-truck-menucard', requestMenucard);
        this.menuCard = responseMenucard;

        //BUILD GALLERY OBJECT
        if(responseTruck[0].images.length > 0){
          for(let i = 0; i < this.truck.images.length; i++ ){
            this.gallery.tmb.push({name:this.truck.images[i].name,active:false })
            //console.log('AUS DEM GALLERY FOR: '+this.truck.images[i].name);
          }
          this.gallery.img = this.truck.images[0].name;
          this.gallery.tmb[0].active = true;
        }

        //GET LOCATIONS
        let requestLocations = {
          selectorTable:'truck',
          selectorValue: this.$route.params.id
        };
        let responseLocations;
        responseLocations = await this.apiData('TruckView.vue getTruck()','post','get-confirmed-future-locations', requestLocations);
        this.locations = responseLocations;
        this.markers = this.createMarketObject(responseLocations);
       

       

        this.loading = false;
        
      },
      createMarketObject(responseLocations) {
    const markerMap = {}; // Verwende ein Objekt, um Locations nach ihrer ID zu gruppieren

    responseLocations.forEach(location => {
              const locId = location.location.id;

              // Prüfen, ob die Location-ID bereits existiert
              if (!markerMap[locId]) {
                  // Wenn die Location noch nicht existiert, füge sie hinzu
                  markerMap[locId] = {
                      id: location.location.id,
                      name: location.location.name,
                      lat: location.location.lat,
                      long: location.location.long,
                      street: location.location.street,
                      zip: location.location.zip,
                      city: location.location.city,
                      region: location.location.region.name,
                      dates: []
                  };
              }

              // Füge die Start- und End-Daten in das "dates"-Array ein
              markerMap[locId].dates.push({
                  start: location.start,
                  end: location.end
              });
          });

          // Umwandeln des markerMap-Objekts in ein Array, das zurückgegeben wird
          return Object.values(markerMap);
      },

      formatDate(dateString) {
        return dayjs.utc(dateString).local().format('DD.MM.YY HH:mm');
      },


calculateMapCenterAndZoom(truck) {
  let totalX = 0;
  let totalY = 0;
  let totalPoints = 0;

  let minLng = Infinity;
  let minLat = Infinity;
  let maxLng = -Infinity;
  let maxLat = -Infinity;

  truck.region.forEach(region => {
    const polygon = JSON.parse(region.poligon);

    if (polygon.type === "Polygon") {
      const coordinates = polygon.coordinates[0];

      coordinates.forEach(coord => {
        const [lng, lat] = coord;
        totalX += lng;
        totalY += lat;
        totalPoints++;

        // Berechnen der Bounding Box
        if (lng < minLng) minLng = lng;
        if (lng > maxLng) maxLng = lng;
        if (lat < minLat) minLat = lat;
        if (lat > maxLat) maxLat = lat;
      });
    } else if (polygon.type === "MultiPolygon") {
      polygon.coordinates.forEach(subPolygon => {
        subPolygon[0].forEach(coord => {
          const [lng, lat] = coord;
          totalX += lng;
          totalY += lat;
          totalPoints++;

          // Berechnen der Bounding Box
          if (lng < minLng) minLng = lng;
          if (lng > maxLng) maxLng = lng;
          if (lat < minLat) minLat = lat;
          if (lat > maxLat) maxLat = lat;
        });
      });
    }
  });

  // Berechnen des Mittelpunkts
  if (totalPoints === 0) {
    this.mapCenter = [null, null]; // Falls keine gültigen Koordinaten vorhanden sind
  } else {
    const centerX = totalX / totalPoints;
    const centerY = totalY / totalPoints;
    this.mapCenter = [centerX, centerY]; // Speicherung des Mittelpunkts
  }
//console.log('############## MAP '+this.map);
  // Setzen des Kartenzentrums und Anpassung des Zoomfaktors
  if (this.map) {
    const bounds = [[minLng, minLat], [maxLng, maxLat]];
    this.map.fitBounds(bounds, { padding: 20, maxZoom: 14 }); // Passt die Karte an, um alle Polygone sichtbar zu machen
    this.zoom = this.map.getZoom(); // Speichert den berechneten Zoomfaktor
  }
}






    },
  };
</script>
<style lang="scss">
.headerLogo{
  margin-bottom:0 !important;
  background-position: center center;
  background-size:contain;
  background-repeat: no-repeat;
}
.galleryTmb{
  cursor: pointer;
  height:76px;
  background-position: center center;
  background-size:cover;
  background-repeat: no-repeat;
  border:2px solid #fff;
  box-sizing: border-box;
}

.galleryTmbOuter{
  border:2px solid #000;
  box-sizing: border-box;
}
.galleryTmbOuter.active{
  border:2px solid #f9b233;
  box-sizing: border-box;
}
.galleryImg{
  background-position: center center;
  background-size:cover;
  background-repeat: no-repeat;
  margin-bottom: 1rem;
}







.custom-marker {
  width: 20px;
  height: 20px;
  background-color: #f9b233;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
}



.mapDetails{
  border-bottom:1px solid var(--bs-border-color);
}


</style>