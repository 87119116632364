<template>
  <div class="container  py-4" >
  <div class="row d-flex justify-content-center" style="margin-top: auto; margin-bottom: auto; ">
    <div class="col-md-4">
      <div class="card">
        <div class="card-header">
          {{ $t('card.title.register') }}
        </div>
        <div class="card-body">
          <Register :apiData="apiData" />
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import Register from '@/components/Register.vue'
export default {
  name: 'RegisterView',
  components: {
    Register
  },
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {
    apiData: { type: Function },
  },
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {};
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {},
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {},
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
  // created() {},
  // beforeMount() {},
  // render(h) { return h(); },
  // mounted() {},
  // beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {},
};
</script>
<style lang="scss">
</style>

