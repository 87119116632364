<template>
  <div class="container  py-4" >

  
    <div class="row d-flex justify-content-center" style="margin-top: auto; margin-bottom: auto; ">
        
      <form @submit.prevent="submitForm">
    <div class="row d-flex">
     
      <div class="col-md-12 text-center">
        <h1>Hast Du Fragen oder brauchst Unterstützung?</h1>
          <p class="lead">Egal, ob Du Hilfe bei der Einrichtung Deines Accounts benötigst, Unterstützung bei Deinen Buchungen suchst oder einfach eine allgemeine Anfrage hast – wir sind für Dich da! Unser Team von Foodtruck Locator freut sich darauf, Dir weiterzuhelfen. Dein Anliegen ist uns wichtig, und wir möchten sicherstellen, dass Du das Beste aus unserer Plattform herausholst.</p>
          <p class="lead">Fülle einfach das untenstehende Formular aus, und wir kümmern uns darum. Ganz gleich, ob Du ein Problem hast, das gelöst werden muss, oder einfach nur ein paar Fragen hast – wir melden uns so schnell wie möglich bei Dir. Deine Zufriedenheit steht bei uns an erster Stelle!</p>
      </div>
       
      <div class="col-md-6" >
       
          <!-- Firma -->
          <div class="form-floating mb-3">
            <input type="text" :class="{'form-control': true, 'is-invalid': errors.firma}" id="firmaField" v-model="form.firma" placeholder="Firma">
            <label for="firmaField">{{ $t('form.label.company') }}</label>
            <div class="invalid-feedback" v-if="errors.firma">
              <p v-for="(item, x) in errors.firma" :key="x">{{ item }}</p>
            </div>
          </div>

          <!-- Name -->
          <div class="form-floating mb-3">
            <input type="text" :class="{'form-control': true, 'is-invalid': errors.name}" id="nameField" v-model="form.name" placeholder="Name">
            <label for="nameField">{{ $t('form.label.name') }}</label>
            <div class="invalid-feedback" v-if="errors.name">
              <p v-for="(item, x) in errors.name" :key="x">{{ item }}</p>
            </div>
          </div>

          <!-- Email Adresse -->
          <div class="form-floating mb-3">
            <input type="email" :class="{'form-control': true, 'is-invalid': errors.email}" id="emailField" v-model="form.email" placeholder="E-Mail">
            <label for="emailField">{{ $t('form.label.email') }}</label>
            <div class="invalid-feedback" v-if="errors.email">
              <p v-for="(item, x) in errors.email" :key="x">{{ item }}</p>
            </div>
          </div>

          <!-- Nachricht -->
          <div class="form-floating mb-3">
            <textarea :class="{'form-control': true, 'is-invalid': errors.nachricht}" id="nachrichtField" v-model="form.nachricht" placeholder="Nachricht" style="height: 150px"></textarea>
            <label for="nachrichtField">{{ $t('form.label.message') }}</label>
            <div class="invalid-feedback" v-if="errors.nachricht">
              <p v-for="(item, x) in errors.nachricht" :key="x">{{ item }}</p>
            </div>
          </div>

          

        
        
      </div>
      <div class="col-md-6 d-flex align-items-stretch flex-column" >
          <!-- Select Menü: Ich bin ein... -->
          <div class="form-floating mb-3">
            <select class="form-select" id="ichBinField" v-model="form.ichBin">
              <option value="" disabled>{{ $t('form.label.iAm') }}</option>
              <option value="foody">{{ $t('form.select.foody') }}</option>
              <option value="standortanbieter">{{ $t('form.select.locationProviders') }}</option>
              <option value="foodtruckbetreiber">{{ $t('form.select.foodtruckProviders') }}</option>
            </select>
            <label for="ichBinField">{{ $t('form.label.iAm') }}</label>
            <div class="invalid-feedback" v-if="errors.ichBin">
              <p v-for="(item, x) in errors.ichBin" :key="x">{{ item }}</p>
            </div>
          </div>

          <!-- Checkboxen -->
           <div class="card flex-grow-1" style="margin-bottom:15px;">
            <div class="card-body">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="allgemeinField" v-model="form.allgemein" checked>
                <label class="form-check-label" for="allgemeinField">Allgemeine Anfrage</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="supportField" v-model="form.support">
                <label class="form-check-label" for="supportField">Support Anfrage</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="accountHilfeField" v-model="form.accountHilfe">
                <label class="form-check-label" for="accountHilfeField">Hilfe bei der Accounteinrichtung</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="angeboteHilfeField" v-model="form.angeboteHilfe">
                <label class="form-check-label" for="angeboteHilfeField">Hilfe bei Angeboten</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="buchungenHilfeField" v-model="form.buchungenHilfe">
                <label class="form-check-label" for="buchungenHilfeField">Hilfe bei Buchungen</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-center">
            <!-- Absenden -->
            <div class="">
            <button type="submit" class="btn btn-primary btn-lg">{{ $t('button.sendMessage') }}</button>
          </div>
        </div>
    </div>
  </form>


  <!-- Vertically centered modal -->
  <div class="modal" id="myModal" >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Deine Anfrage wurde versendet.</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              
                  <p>Vielen Dank für Deine Nachricht! Dein Anliegen liegt uns wirklich am Herzen, und wir freuen uns sehr über Deine Kontaktaufnahme. Unser Team wird sich so schnell wie möglich bei Dir melden, um Dir weiterzuhelfen. Ob es um allgemeine Fragen, Unterstützung oder die Einrichtung Deines Accounts geht – wir sind für Dich da und freuen uns darauf, Dich bestmöglich zu unterstützen.</p>

                    <p>Bis bald und herzliche Grüsse, Dein Foodtruck Locator Team!</p>
                </div>
              
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" @click="modalClose()">{{ $t('button.close') }}</button>
            </div>
          </div>
        </div>
      </div>



    </div>
  </div>
</template>
<script>
import { Modal } from "bootstrap";
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
     apiData:{type: Function}
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        form: {
        firma: '',
        name: '',
        email: '',
        nachricht: '',
        ichBin: 'foody',
        allgemein: true,
        support: false,
        accountHilfe: false,
        angeboteHilfe: false,
        buchungenHilfe: false
      },
      errors: {}
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    mounted() {
      this.uniqueModal = new Modal(document.getElementById("myModal"),{ keyboard: false });
    },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      async submitForm() {
        this.errors={};
        let response;
        response = await this.apiData('ContactView.vue submitForm())','post','contact-submit', this.form);
        if (response.status === 'failure'){
          this.errors = response.errors;
          //console.log(this.errors);
          this.$toast.open({
            message: 'Es ist ein Fehler aufgetreten',
            type: 'error',
            duration:3000,
          });
        }else{

          this.form = {
              firma: '',
              name: '',
              email: '',
              nachricht: '',
              ichBin: 'foody',
              allgemein: true,
              support: false,
              accountHilfe: false,
              angeboteHilfe: false,
              buchungenHilfe: false
            };
          //this.uniqueModal.hide();
          this.errors = {};
          this.uniqueModal.show();
          this.$toast.open({
            message: 'Deine Anfrage wurde erfolgreich versendet',
            type: 'success',
            duration:3000,
          });
          
        }  
      },
      modalClose(){
        this.uniqueModal.hide();
      }
    },
  };
</script>
<style lang="scss">
</style>