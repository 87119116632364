<template>
  <div class="container  py-4">
  <div class="row d-flex justify-content-center" style="margin-top: auto; margin-bottom: auto; ">
    <div class="col-md-4">
      <div class="card">
        <div class="card-header">
          {{ $t('card.title.login') }}
        </div>
        <div class="card-body">
          <Login :setLoginStatus="setLoginStatus" :apiData="apiData" :setUserData="setUserData" :authFunction="authFunction" />
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
  import Login from '@/components/Login.vue'
  export default {
    name: 'LoginView',
    components: {
      Login
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      apiData: { type: Function },
      setLoginStatus: {type:Function},
      setUserData: {type:Function},
      authFunction: {type:Function},
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>