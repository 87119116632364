<template>
    <div class="container  py-4" >
        <h2>Allgemeine Geschäftsbedingungen (AGB)</h2>

        <h3>1. Geltungsbereich</h3>
        <p>Diese Allgemeinen Geschäftsbedingungen (AGB) regeln die Nutzung der Plattform Foodtruck-Locator.ch (nachfolgend „Foodtruck Locator“) durch Foodtruck-Betreiber und Standortanbieter (gemeinsam „Nutzer“). Sie gelten für alle Vereinbarungen zwischen Nutzern und Foodtruck Locator in Bezug auf Buchungen und die Nutzung der Plattform.</p>

        <h3>2. Vertragsschluss und Buchungsprozess</h3>
        <p>Eine Buchung gilt als verbindlich, sobald der Standortanbieter diese über Foodtruck Locator bestätigt. Nach der Bestätigung kommt ein rechtskräftiger Vertrag zwischen dem Standortanbieter, dem Foodtruck-Betreiber und Foodtruck Locator zustande.</p>
        <p>Foodtruck-Betreiber können auf der Plattform Buchungsanfragen stellen, und Standortanbieter müssen diese bestätigen. Fixpreisangebote müssen ebenfalls vom Standortanbieter bestätigt werden, bevor sie rechtsverbindlich sind.</p>
        <p>Auktionen werden auf der Plattform durchgeführt, und die Auktion ist rechtsverbindlich, sobald sie endet. Der Meistbietende erhält den Zuschlag. Auktionen schließen 48 Stunden vor Beginn des Buchungszeitraums, und bis dahin können Gebote abgegeben werden.</p>

        <h3>3. Pflichten der Nutzer</h3>
        <p>Foodtruck-Betreiber und Standortanbieter sind verpflichtet, die vereinbarten Leistungen zu erbringen. Die Parteien müssen sicherstellen, dass die Buchungsdetails korrekt angegeben sind und dass beide Parteien die vereinbarten Zeiten und Bedingungen einhalten.</p>
        <p>Foodtruck Locator vermittelt nur die Buchungen und übernimmt keine Verantwortung für den tatsächlichen Ablauf vor Ort oder für Zahlungen zwischen den Nutzern.</p>

        <h3>4. Gebühren und Zahlungsbedingungen</h3>
        <p>Die Nutzung der Plattform ist kostenlos. Allerdings fallen bei bestätigten Buchungen oder gewonnenen Auktionen Vermittlungsgebühren an. Die Vermittlungsgebühr beträgt 10% des Buchungsbetrages und wird dem Standortanbieter in Rechnung gestellt.</p>
        <p>Die Rechnung wird automatisch generiert und ist am Tag des Buchungsbeginns fällig. Foodtruck Locator übernimmt keine Verantwortung für die Zahlungsabwicklung zwischen dem Standortanbieter und dem Foodtruck-Betreiber. Diese müssen eigenständig über Zahlungsmodalitäten entscheiden.</p>

        <h3>5. Stornierung und Vertragsbruch</h3>
        <p>Eine bestätigte Buchung ist verbindlich und kann nicht ohne Zustimmung beider Parteien storniert werden. Im Falle eines Vertragsbruchs hat die geschädigte Partei das Recht, Schadensersatz zu fordern. Der Standortanbieter kann die Vermittlungsgebühr von Foodtruck Locator vom Foodtruck-Betreiber verlangen, falls dieser seinen vertraglichen Verpflichtungen nicht nachkommt. Ebenso kann der Foodtruck-Betreiber Ansprüche gegen den Standortanbieter geltend machen, wenn dieser den Vertrag nicht erfüllt.</p>

        <h3>6. Haftungsausschluss</h3>
        <p>Foodtruck Locator haftet nicht für Schäden, die durch die Nichterfüllung eines Vertrages zwischen Standortanbieter und Foodtruck-Betreiber entstehen. Die Plattform stellt lediglich die technische Infrastruktur zur Verfügung, um Buchungen und Auktionen zu vermitteln. Foodtruck Locator haftet nicht für etwaige Fehler, technische Störungen oder Ausfälle der Plattform.</p>

        <h3>7. Datenschutz</h3>
        <p>Foodtruck Locator verpflichtet sich, personenbezogene Daten der Nutzer gemäß den gesetzlichen Datenschutzbestimmungen zu verarbeiten. Die vollständige Datenschutzerklärung kann auf der Website eingesehen werden.</p>

        <h3>8. Widerrufsrecht</h3>
        <p>Da die Buchungen auf Foodtruck Locator Dienstleistungen betreffen, die an spezifische Zeiträume gebunden sind, besteht kein Widerrufsrecht für bestätigte Buchungen oder gewonnene Auktionen.</p>

        <h3>9. Änderung der AGB</h3>
        <p>Foodtruck Locator behält sich das Recht vor, diese AGB jederzeit zu ändern. Änderungen werden den Nutzern rechtzeitig mitgeteilt. Die weitere Nutzung der Plattform nach einer solchen Mitteilung gilt als Zustimmung zu den geänderten Bedingungen.</p>

        <h3>10. Schlussbestimmungen</h3>
        <p>Sollten einzelne Bestimmungen dieser AGB unwirksam sein, berührt dies nicht die Wirksamkeit der übrigen Bestimmungen. Anstelle der unwirksamen Bestimmung tritt eine dem Zweck der Bestimmung entsprechende Regelung in Kraft.</p>
        <p>Es gilt das Recht der Schweiz. Gerichtsstand ist der Sitz von Foodtruck Locator.</p>
      </div>

</template>
<script>
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>