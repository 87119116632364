<template>
  <div class="container  py-4">
      <div class="row d-flex justify-content-center" style="margin-top: auto; margin-bottom: auto; ">
        <!-- EMAIL SETTINGS -->
        <div class="col-md-6">
          <div class="card h-100">
            <div class="card-header"><h5>{{ $t('card.title.emailSettings') }}</h5></div>
            <div class="card-body">
              <ol class="list-group list-group-flush">
                <li class="list-group-item d-flex justify-content-between align-items-start" v-if="userData.role === 'trucker' || userData.role === 'admin' ">
                  <div class="form-check form-switch d-flex justify-content-between align-items-start w-100"  style="padding-left: 0 !important">
                    <label class="form-check-label" for="mail1" >{{ $t('form.label.mailSettings1') }}</label>
                    <input class="form-check-input" type="checkbox" role="switch" id="mail1" v-model="emailSettings.newOfferts">
                  </div>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-start"  v-if="userData.role === 'trucker' || userData.role === 'admin' ">
                  <div class="form-check form-switch d-flex justify-content-between align-items-start w-100"  style="padding-left: 0 !important">
                    <label class="form-check-label" for="mail2">{{ $t('form.label.mailSettings2') }}</label>
                    <input class="form-check-input" type="checkbox" role="switch" id="mail2" v-model="emailSettings.auctionOverbid">
                  </div>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-start"  v-if="userData.role === 'trucker' || userData.role === 'admin' ">
                  <div class="form-check form-switch d-flex justify-content-between align-items-start w-100"  style="padding-left: 0 !important">
                    <label class="form-check-label" for="mail3">{{ $t('form.label.mailSettings3') }}</label>
                    <input class="form-check-input" type="checkbox" role="switch" id="mail3" v-model="emailSettings.auctionWin">
                  </div>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-start"  v-if="userData.role === 'location' || userData.role === 'admin' ">
                  <div class="form-check form-switch d-flex justify-content-between align-items-start w-100"  style="padding-left: 0 !important">
                    <label class="form-check-label" for="mail4">{{ $t('form.label.mailSettings4') }}</label>
                    <input class="form-check-input" type="checkbox" role="switch" id="mail4" v-model="emailSettings.auctionEnd">
                  </div>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-start"  v-if="userData.role === 'trucker' || userData.role === 'admin' ">
                  <div class="form-check form-switch d-flex justify-content-between align-items-start w-100"  style="padding-left: 0 !important">
                    <label class="form-check-label" for="mail5">{{ $t('form.label.mailSettings5') }}</label>
                    <input class="form-check-input" type="checkbox" role="switch" id="mail5" v-model="emailSettings.bookingConfirm">
                  </div>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-start"  v-if="userData.role === 'trucker' || userData.role === 'admin' ">
                  <div  class="form-check form-switch d-flex justify-content-between align-items-start w-100"  style="padding-left: 0 !important">
                    <label class="form-check-label" for="mail6">{{ $t('form.label.mailSettings6') }}</label>
                    <input class="form-check-input" type="checkbox" role="switch" id="mail6" v-model="emailSettings.bookingCancel">
                  </div>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-start"  v-if="userData.role === 'location' || userData.role === 'admin' ">
                  <div  class="form-check form-switch d-flex justify-content-between align-items-start w-100"  style="padding-left: 0 !important">
                    <label class="form-check-label" for="mail7">{{ $t('form.label.mailSettings7') }}</label>
                    <input class="form-check-input" type="checkbox" role="switch" id="mail7" v-model="emailSettings.offertBooked">
                  </div>
                </li>         
              </ol>
            </div>
            <div class="card-footer  align-self-end justify-content-end d-flex w-100">
              <button class="btn btn-primary" @click="updateEmailSettings()">{{ $t('button.save') }}</button>
            </div>
          </div>
        </div>
        <!-- EMAIL SETTINGS END -->
        
        <div class="col-md-6 d-flex flex-column justify-content-between">
          <!-- ACCOUNT DETAILS SETTINGS -->
          <div class="card" style="margin-bottom:20px">
            <div class="card-header"><h5>{{ $t('card.title.accountSettings') }}</h5></div>
            <div class="card-body">
              <ol class="list-group list-group-flush">
                <li class="list-group-item d-flex justify-content-between align-items-start">
                  <div>
                    {{ $t('form.label.accountName') }}: {{ userData.name }}
                  </div>
                  <button class="btn btn-primary btn-sm" @click="nameModal.show()">{{ $t('button.change') }}</button>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-start">
                  <div>
                    {{ $t('form.label.email') }}: {{ userData.email }}
                  </div>
                  <button class="btn btn-primary btn-sm" @click="emailModal.show()">{{ $t('button.change') }}</button>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-start">
                  <div>
                    {{ $t('form.label.password') }}: ***********************
                  </div>
                  <button class="btn btn-primary btn-sm"  @click="passwordModal.show()">{{ $t('button.change') }}</button>
                </li>
              </ol>
            </div>
            
          </div>
          <!-- ACCOUNT DETAILS SETTINGS END -->
           <!-- GENERAL SETTINGS -->
          <div class="card">
            <div class="card-header"><h5>{{ $t('card.title.generalSettings') }}</h5></div>
            <div class="card-body">
              <ol class="list-group list-group-flush">
                <li class="list-group-item d-flex justify-content-between align-items-start">
                  <label for="countryField" class="form-label" >{{ $t('form.label.language') }}: </label>
                    <select class="form-select"  id="countryField" v-model="language" style="width:200px">
                      <option value="de">Deutsch</option>
                      <option value="en" disabled>Englisch</option>
                      <option value="fr" disabled>Französisch</option>
                      <option value="it" disabled>Italienisch</option>
                    </select>
                    
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-start">
                  <div>
                    {{ $t('form.label.theme') }}:
                  </div>
                   <!-- COLOR MODE DROPDOWN -->
                   <div class="btn-group dropup">
                    <div  class="" data-bs-toggle="dropdown" aria-expanded="false" style="padding: 0;cursor: pointer;">
                      <font-awesome-icon class="navbarIcon me-2" icon="fa-solid fa-circle-half-stroke" v-if="colorMode === 'auto'" @click="displayColorNavigation = !displayColorNavigation" />
                      <font-awesome-icon class="navbarIcon me-2" icon="fa-solid fa-sun" v-if="colorMode === 'light'" @click="displayColorNavigation = !displayColorNavigation" />
                      <font-awesome-icon class="navbarIcon me-2" icon="fa-solid fa-moon" v-if="colorMode === 'dark'" @click="displayColorNavigation = !displayColorNavigation" />
                    </div>
                    <div class="dropdown-menu " style="padding:0"> 
                      <div class="list-group list-group-flush">
                        <a :class="{'list-group-item': true, 'list-group-item-action': true, 'active': storageMode === 'light'}" href="#" @click="setColorModeInternal('light')">
                          <font-awesome-icon icon="fa-solid fa-sun" />
                          {{ $t('nav.color.light') }}
                        </a>
                        <a :class="{'list-group-item': true, 'list-group-item-action': true, 'active': storageMode === 'dark'}" href="#"  @click.passive="setColorModeInternal('dark')" >
                          <font-awesome-icon icon="fa-solid fa-moon"  />
                          {{ $t('nav.color.dark') }}
                        </a>
                        <a :class="{'list-group-item': true, 'list-group-item-action': true, 'active': storageMode === 'auto'}" href="#" @click.passive="setColorModeInternal('auto')" >
                          <font-awesome-icon icon="fa-solid fa-circle-half-stroke"  />
                          {{ $t('nav.color.auto') }}
                        </a>
                      </div>
                    </div>
                  </div>
                  <!-- COLOR MODE DROPDOWN END -->
                </li>
              </ol>
            </div>
            
          </div>
          <!-- GENERAL SETTINGS END -->
        </div>
        
      </div>
       <!-- CHANGE EMAIL MODAL -->
       <div class="modal" id="emailModal" data-bs-backdrop="static" >
        <div class="modal-dialog modal-dialog-centered ">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ $t('modal.title.changeEmail') }}</h5>
            </div>
            <div class="modal-body">
             
                  <div class="mb-3 form-floating">
                    <input type="email" :class="{'form-control': true, 'is-invalid': errors.email}"  id="emailField" v-model="newEmail" placeholder="E-Mail">
                    <label  for="emailField"  class="form-label">{{ $t('form.label.email') }}</label>
                    <div class="invalid-feedback" v-if="errors.email">
                      <p v-for="(item, x) in errors.email" :key='x' >{{ errors.email[x] }}</p>
                    </div>
                  </div>
                  <p>{{ $t('modal.text.changeEmail') }}</p>      
                
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="emailModal.hide()">{{ $t('button.cancel') }}</button>
              <button type="button" class="btn btn-primary" @click="updateEmailAddress()">{{ $t('button.save') }}</button>
            </div>
          </div>
        </div>
      </div>
      <!-- CHANGE EMAIL MODAL -->
      <!-- CHANGE NAME MODAL -->
      <div class="modal" id="nameModal" data-bs-backdrop="static" >
      <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('modal.title.changeName') }}</h5>
          </div>
          <div class="modal-body">
            
                <div class="mb-3 form-floating">
                  <input type="email" :class="{'form-control': true, 'is-invalid': errors.email}"  id="nameField" v-model="newName" placeholder="Name">
                  <label  for="nameField"  class="form-label">{{ $t('form.label.name') }}</label>
                  <div class="invalid-feedback" v-if="errors.email">
                    <p v-for="(item, x) in errors.email" :key='x' >{{ errors.email[x] }}</p>
                  </div>
                </div>
              
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="nameModal.hide()">{{ $t('button.cancel') }}</button>
            <button type="button" class="btn btn-primary" @click="updateAccountName()">{{ $t('button.save') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!-- CHANGE NAME MODAL -->
     <!-- CHANGE PASSWORD MODAL -->
     <div class="modal" id="passwordModal" data-bs-backdrop="static" >
      <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('modal.title.changePassword') }}</h5>
          </div>
          <div class="modal-body">
            
            <div class="form-floating mb-3">
              <input type="password" :class="{'form-control': true, 'is-invalid': errors.password}"  id="passwordField" v-model="newPassword" placeholder="Password">
              <label  for="passwordField" class="form-label">{{ $t('form.label.password') }}</label>
              <password-meter :password="newPassword" />
              <div class="invalid-feedback" v-if="errors.password">
                <p v-for="(item, x) in errors.password" :key='x' >{{ errors.password[x] }}</p>
              </div>
            </div>
            
            <div class="form-floating mb-3">
              <input type="password" :class="{'form-control': true, 'is-invalid': errors.password_confirmation}"  id="confirmField" v-model="newPassword_confirmation" placeholder="Password Confirmation">
              <label for="confirmField" class="form-label">{{ $t('form.label.passwordConfirmation') }}</label>
              
              <div class="invalid-feedback"  v-if="errors.password_confirmation">
                <p v-for="(item, x) in errors.password_confirmation" :key='x' >{{ errors.password_confirmation[x] }}</p>
              </div>
            </div>
                <p>{{ $t('modal.text.changePassword') }}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="passwordModal.hide()">{{ $t('button.cancel') }}</button>
            <button type="button" class="btn btn-primary" @click="updatePassword()">{{ $t('button.save') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!-- CHANGE PASSWORD MODAL -->
  </div>
</template>
<script>
import PasswordMeter from 'vue-simple-password-meter';
  import { Modal } from "bootstrap";
  export default {
    name: '${COMPONENT_NAME}',
    components: {
      PasswordMeter
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      setColorMode:{type: Function},
      setUserData:{type:Function},
      apiData:{type:Function},
      resetUserData:{type:Function},
      setLoginStatus:{type:Function},
      userData: {required:true},
      colorMode:{required: true} ,
      colorTheme:{required:true}
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        newPassword:'',
        newPassword_confirmation:'',
        errors:{},
        newName:this.userData.name,
        newEmail:this.userData.email,
        storageMode: localStorage.getItem('colorMode'),
        language:this.userData.user_settings.language,
        emailSettings:{
          newOfferts:true,
          auctionOverbid:true,
          auctionWin:true,
          auctionEnd:true,
          bookingConfirm:true,
          bookingCancel:true,
          offertBooked:true,

        }
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
     mounted() {
      this.passwordModal = new Modal(document.getElementById("passwordModal"),{ keyboard: false });
      this.emailModal = new Modal(document.getElementById("emailModal"),{ keyboard: false });
      this.nameModal = new Modal(document.getElementById("nameModal"),{ keyboard: false });
      this.setEmailSettings();
     },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      setColorModeInternal(value){
        localStorage.setItem('colorMode', value);
        window.dispatchEvent(new Event('storage'));
        this.setColorMode(localStorage.getItem('colorMode'));
        this.storageMode = localStorage.getItem('colorMode');

      },
      async updateEmailAddress(){
        let request ={
          owner: this.userData.uuid,
          email: this.newEmail
        }
        let response;
        response = await this.apiData('UserSettingsView.vue updateEmailAddress()','post','update-email-address', request);
        if (response.status === 'failure'){
          this.errors = response.errors;
          this.$toast.open({
            message: this.$t('toast.error'),
            type: 'error',
            duration:3000,
          });
        }else{
          this.errors={};
          this.emailModal.hide();
          this.$toast.open({
            message: 'Deine E-Mail Adresse wurde aktualisiert, du musst Dich erneut anmelden und den Code eingeben den wir dir auf deine neue E-Mail Adresse '+this.newEmail+' geschickt haben',
            type: 'success',
            duration:3000,
          });
          this.logout();
          
        } 
      },
      async updatePassword(){
        let request ={
          owner: this.userData.uuid,
          password: this.newPassword,
          password_confirmation: this.newPassword_confirmation,
        }
        let response;
        response = await this.apiData('UserSettingsView.vue updatePassword()','post','update-password', request);
        if (response.status === 'failure'){
          this.errors = response.errors;
          this.$toast.open({
            message: this.$t('toast.error'),
            type: 'error',
            duration:3000,
            
          });
          //console.log(response.errors);
        }else{
          this.errors={};
          this.passwordModal.hide();
          this.$toast.open({
            message: this.$t('toast.changePassword'),
            type: 'success',
            duration:3000,
          });
          this.logout();
          
        } 
      },
      async updateAccountName(){
        let request ={
          owner: this.userData.uuid,
          name: this.newName
        }
        let response;
        response = await this.apiData('UserSettingsView.vue updateAccountName()','post','update-account-name', request);
        if (response.status === 'failure'){
          this.errors = response.errors;
          this.$toast.open({
            message: this.$t('toast.error'),
            type: 'error',
            duration:3000,
          });
        }else{
          this.errors={};
          this.nameModal.hide();
          this.$toast.open({
            message: this.$t('toast.changeName'),
            type: 'success',
            duration:3000,
          });
          this.setUserData();
        
          
        } 
      },
      async updateEmailSettings(){
        let response;
        response = await this.apiData('UserSettingsView.vue updateEmailSettings()','post','update-email-settings', this.emailSettings);
        if (response.status === 'failure'){
          this.$toast.open({
            message: this.$t('toast.error'),
            type: 'error',
            duration:3000,
          });
        }else{
          this.setUserData();
          this.$toast.open({
            message: this.$t('toast.emailSettings'),
            type: 'success',
            duration:3000,
          });
          
        } 
      },
       setEmailSettings(){
       
        if(this.userData.user_settings.email_newofferts === 0){
          this.emailSettings.newOfferts = false;
        }else{
          this.emailSettings.newOfferts = true;
        }
        if(this.userData.user_settings.email_auctionoverbid === 0){
          this.emailSettings.auctionOverbid = false;
        }else{
          this.emailSettings.auctionOverbid = true;
        }
        if(this.userData.user_settings.email_auctionwin === 0){
          this.emailSettings.auctionWin = false;
        }else{
          this.emailSettings.auctionWin = true;
        }
        if(this.userData.user_settings.email_auctionend === 0){
          this.emailSettings.auctionEnd = false;
        }else{
          this.emailSettings.auctionEnd = true;
        }
        if(this.userData.user_settings.email_bookingconfirm === 0){
          this.emailSettings.bookingConfirm = false;
        }else{
          this.emailSettings.bookingConfirm = true;
        }
        if(this.userData.user_settings.email_bookingcancel === 0){
          this.emailSettings.bookingCancel = false;
        }else{
          this.emailSettings.bookingCancel = true;
        }
        if(this.userData.user_settings.email_booking === 0){
          this.emailSettings.offertBooked = false;
        }else{
          this.emailSettings.offertBooked = true;
        }

      },
      logout(){
        localStorage.removeItem('access_token');
        localStorage.removeItem('userUUID');
        this.setLoginStatus(false);
        this.resetUserData();
        this.$router.push('/login');
      }
    },
  };
</script>
<style lang="scss">
</style>