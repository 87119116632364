<template>
  <div class="container  py-4">
    <div class="row d-flex justify-content-center" style="margin-top: auto; margin-bottom: auto; ">
    <!-- EMAIL ADDRESS -->
    <div class="col-md-4" v-if="step === 1">
      <div class="card">
        <div class="card-header">
         <h5>{{ $t('card.title.resetPassword') }}</h5>
        </div>
        <div class="card-body">
          <form  @submit.prevent="resetPassword('checkMail')"  style="margin-bottom:0 !important">
            <div class="mb-3 form-floating">
              <input type="email" :class="{'form-control': true, 'is-invalid': errors.email}"  id="emailField" v-model="email" placeholder="E-Mail">
              <label  for="emailField"  class="form-label">E-Mail</label>
              <div class="invalid-feedback" v-if="errors.email">
                <p v-for="(item, x) in errors.email" :key='x' >{{ errors.email[x] }}</p>
              </div>
            </div>
            <div class="mb-3">
              <input type="submit" value="Passwort Zurücksetzen" class="form-control btn btn-primary" >
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- EMAIL ADDRESS END -->
    <!-- EMAIL VERIFICATION -->
    <div class="col-md-6" v-if="step === 2">
      <div class="card">
        <div class="card-header text-center">
         <h5>{{ $t('modal.title.emailVerification') }}</h5>
        </div>
        <div class="card-body text-center">
          <p class="card-text">{{ $t('card.body.emailVerification') }}</p>
              <h6 class="card-title">{{ $t('card.title.emailVerification') }}</h6>
              <br />
              <v-otp-input
                style="display: flex;justify-content: center;"
                ref="otpInput"
                input-classes="otp-input"
                :conditionalClass="['one', 'two', 'three', 'four']"
                separator="-"
                inputType="letter-numeric"
                :num-inputs="4"
                v-model:value="bindValue"
                :should-auto-focus="true"
                :should-focus-order="true"
                @on-complete="resetPassword('verifyMail')"
              />
              <br />
              <h5 class="text-danger" v-if="verifyError">{{ $t('error.emailVerification') }}</h5>
        </div>
      </div>
    </div>
    <!-- EMAIL VERIFICATION END -->
    <!-- NEW PASSWORD -->
    <div class="col-md-6" v-if="step === 3">
      <div class="card">
        <div class="card-header">
         <h5>{{ $t('card.title.newPassword') }}</h5>
        </div>
        <div class="card-body ">
          
          <div class="form-floating mb-3">
              <input type="password" :class="{'form-control': true, 'is-invalid': errors.password}"  id="passwordField" v-model="newPassword" placeholder="Password">
              <label  for="passwordField" class="form-label">{{ $t('form.label.password') }}</label>
              <password-meter :password="newPassword" />
              <div class="invalid-feedback" v-if="errors.password">
                <p v-for="(item, x) in errors.password" :key='x' >{{ errors.password[x] }}</p>
              </div>
            </div>
            
            <div class="form-floating mb-3">
              <input type="password" :class="{'form-control': true, 'is-invalid': errors.password_confirmation}"  id="confirmField" v-model="newPassword_confirmation" placeholder="Password Confirmation">
              <label for="confirmField" class="form-label">{{ $t('form.label.passwordConfirmation') }}</label>
              
              <div class="invalid-feedback"  v-if="errors.password_confirmation">
                <p v-for="(item, x) in errors.password_confirmation" :key='x' >{{ errors.password_confirmation[x] }}</p>
              </div>
            </div>
            <button type="button" class="btn btn-primary w-100" @click="resetPassword('resetPassword')">{{ $t('button.resetPassword') }}</button>
        </div>
      </div>
    </div>
    <!-- NEW PASSWORD END -->
  </div>
</div>
</template>
<script>
  import VOtpInput from "vue3-otp-input";
  import PasswordMeter from 'vue-simple-password-meter';
  export default {
    name: '${COMPONENT_NAME}',
    components: {
      VOtpInput,
      PasswordMeter
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      apiData:{type:Function}
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        newPassword:'',
        newPassword_confirmation:'',
        verifyError:false,
        bindValue:"",
        step:1,
        errors:{},
        email:''
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      async resetPassword(step){
        let response;
        let request;
        if(step === 'checkMail'){
          request = {
            step: step,
            email: this.email,
          };
          response = await this.apiData('ResetPasswordView.vue resetPassword()','post','reset-password', request);
          if (response.status === 'failure'){
            this.errors = response.errors;
            this.$toast.open({
              message: this.$t('toast.error'),
              type: 'error',
              duration:3000,
            });
            //console.log(response.errors);
          }else{
            this.errors={};
            this.$toast.open({
              message: this.$t('toast.emailVerificationPasswordReset'),
              type: 'success',
              duration:3000,
            });
            this.step = 2;  
            //console.log(response);          
          } 
        }
        if(step === 'verifyMail'){
          request = {
            step: step,
            email: this.email,
            code: this.bindValue
          };
          response = await this.apiData('ResetPasswordView.vue resetPassword()','post','reset-password', request);
          if (response.status === 'failure'){
            this.errors = response.errors;
            this.$toast.open({
              message: this.$t('toast.error'),
              type: 'error',
              duration:3000,
              
            });
            this.verifyError = true;
            //console.log(response.errors);
          }else{
            this.errors={};
            this.$toast.open({
              message: this.$t('toast.resetReady'),
              type: 'success',
              duration:3000,
            });
            this.step = 3;            
          } 
        }
        if(step === 'resetPassword'){
          request = {
            step: step,
            email: this.email,
            code: this.bindValue,
            password: this.newPassword,
            password_confirmation: this.newPassword_confirmation
          };
          response = await this.apiData('ResetPasswordView.vue resetPassword()','post','reset-password', request);
          if (response.status === 'failure'){
            this.errors = response.errors;
            this.$toast.open({
              message: this.$t('toast.error'),
              type: 'error',
              duration:3000,
              
            });
            //console.log(response.errors);
          }else{
            this.errors={};
            this.$toast.open({
              message: this.$t('toast.resetSuccess'),
              type: 'success',
              duration:3000,
            });
            this.$router.push('/login');           
          } 
        }
      }
    },
  };
</script>
<style lang="scss">
</style>